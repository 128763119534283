<script lang="ts">
import Multiselect from 'vue-multiselect'
import addIcon from '@/assets/addIcon.svg';
import deleteIcon from '@/assets/delete.svg';
import selectArrow from '@/assets/select-arrow.svg';
import setaBaixoIcon from '@/assets/seta-baixo.svg';
import searchIcon from '@/assets/search.svg';
import { defineComponent } from 'vue';
import { FILE_MAX_SIZE, IMAGE_MAX_SIZE, FileType, InputType, Option } from '@/Types/InputTypes';
import { vMaska } from "maska"
import { PropType } from 'vue';
import { MaskType } from '@/Types/MaskTypes';
import { getMask } from '@/utils/Masks';
import { colors } from '@/styles/styles'
import { BASE_URL } from "../env/variables";
import infoIcon from '@/assets/info-icon.svg';
import modalService from '@/services/ModalService';
import { Slugify } from '@/utils/formats';
import { Money, Percent } from '@/utils/formats';
import { ModelForm } from '@/services/Form';
import { IRangeValues } from '@/Types/Filters';
import { off } from 'process';
import { authentication } from '@/services/Authentication';

interface IObjectToggleSettings {
    label: string;
    hintText?: string;
}

interface IToggleSettings {
    on: IObjectToggleSettings,
    off: IObjectToggleSettings,
    url?: string,
}

interface IValue {
    inputRange: HTMLInputElement,
    inputText: HTMLInputElement,
}

interface IRangeMinMax {
    minValue: IValue,
    maxValue: IValue,
    progress: HTMLDivElement,
}

export default defineComponent({
    name: "InputField",
    props: {
        name: {type: String, required: true},
        value: {type: [Object, String, Boolean, Number], default: null},
        type: {type: Number as PropType<InputType>, required: true},
        id: String,
        label: String,
        placeholder: {type: String, default: ''},
        isRequired: {type: Boolean, default: false},
        colSpan: {type: Number, default: 0},
        fileType: {type: String as PropType<FileType>, default: FileType.ANY},
        isSelected: {type: Boolean, default: false},
        noLabel: {type: Boolean, default: false},
        labelClasses: {type: String, default: "grey-label"},
        background: {type: Boolean, default: true},
        fontSize: {type: String, default: ''},
        range: {type: Object as PropType<{min: number, max: number, step: number, unit: String, multiply?: number, value?: number, gap?: number}>},
        blueBackground: {type: Boolean, default: false},
        styles: {type: Object, default: undefined},
        labelStyles: {type: [Object, String], default: ''},
        mask: {type: [Number, String] as PropType<MaskType | string>, default: null},
        unMask: {type: Boolean, default: true},
        options: {type: Object as PropType<Option[]>, default: [{code: 'null', name:'-----------'}]},
        autoCompleteOptions: {type: Object as PropType<Option[]>, default: [{code: 'null', name:'-----------'}]},
        isDisabled: {type: Boolean, default: false},
        disableOpenFile: {type: Boolean, default: false},
        noUpdate: {type: Boolean, default: false},
        noMargin: {type: Boolean, default: false},
        chevron: {type: Boolean, default: true},
        hint: {type: Boolean, default: false},
        hintClasses: {type: String, default: undefined},
        radioClasses: {type: String, default: undefined},
        help: String,
        selects: {type: Object, defaul: undefined},
        selectId: {type: String, default: undefined},
        checkBoxColor: {type: String, default: 'white-semi-bg'},
        checkboxGap: {type: Boolean, default: false},
        fitContent: {type: Boolean, default: false},
        instance: {type: Object, default: {}},
        whiteMode: Boolean,
        textSearchFunction: {type: Function, default: (e: any) => {}},
        redefinirSenhaLabelColor: {type: String, default: 'dark-blue'},
        inputContainerClass: {type: String, default: ''},
        ceilValue: {type: Number, default: 1},
        forceUpdatePlaceholder: {type: Boolean, default: false},
        textCenter: {type: Boolean, default: false},
        inputTextType: {type: String, default: 'text'},
        callback: {type: Function, default: () => {}},
        passwordVisibility: {type: Boolean, default: true},
        toggleSettings: {type: Object as PropType<IToggleSettings>, default: {}},
        oneToggleLabel: {type: Boolean, default: false},
        toggleLink: {type: String, default: 'link.'},
        tokenClasses: {type: String, default: ''},
        inputsToken: {type: Number, default: 0},
        pasteClick: {type: Boolean, default: false},
        maxHeight: {type: String, default: '20rem'},
        translateX: {type: Boolean, default: false},
        rangeValues: {type: Object as PropType<IRangeValues>, default: {}},
        textAreaClass: {type: String, default: ''},
        itemsCenter: {type: Boolean, default: false},
        maxLength: {type: String, default: ''},
    },
    directives: { maska: vMaska },
    data() {
        
        const inputMask = getMask(this.mask);
        const disabled = this.isDisabled;
        
        return {
            inputMask,
            addIcon,
            infoIcon,
            searchIcon,
            deleteIcon,
            setaBaixoIcon,
            InputType,
            MaskType,
            FileType,
            selectArrow,
            checked: this.isSelected,
            selectedOption: this.range ? this.range.value || this.range.max : '',
            colors,
            //Filter Select
            focus: false,
            filteredOptions: undefined as Option[] | undefined,
            selected: false,
            disabled,
            errors: null as null | string[],
            showPassword: false,
            Money,
            Percent,
            

            rangeMinMax: {minValue: {}, maxValue: {}} as IRangeMinMax,
            gapValue: 0,

            userAccess: authentication.getUserAccess(),
        }
    },
    components: { Multiselect },
    emits : [
        'input', 'update:value', 'verifyPassword', 'scrollToBottom', 'focus', 'blur', 'getOptions',
    ],
    methods: {
        openFile() {
            if (typeof this.value != 'string')
                return;
            if (this.disableOpenFile)
                return;

            let url: string = '';

            if (!this.value.includes('www.') && !this.value.includes('http')) {
                url += BASE_URL;
            }

            url += this.value;

            window.open(url, '_blank');
        },
        addFile() {
            if (!this.$refs.file.files[0]) return;

            const fileSize = this.$refs.file.files[0].size;

            if(this.fileType == FileType.IMAGE && fileSize > IMAGE_MAX_SIZE) {
                this.$refs.file.value = '';
                modalService.createError("Não é possível enviar uma imagem maior do que 50MB");
                return
            }

            if (this.fileType != FileType.IMAGE && fileSize > FILE_MAX_SIZE) {
                this.$refs.file.value = '';
                modalService.createError("Não é possível enviar um arquivo maior do que 20MB");
                return
            }

            this.selectedOption = this.placeholder;
            this.emitInput(this.$refs.file.files[0]);
        },
        removeFile() {
            this.$refs.file.value = null;
            this.selectedOption = null;
            this.emitInput(null);
        },
        select(element) {
            if (this.disabled) return; 
            this.checked = element.target.checked;
            this.emitInput(this.checked);
        },

        subtractValue(){
            const subtractValue = this.value - 1 
            if(subtractValue < 1)  {
                this.emitInput(this.value)
                return;
            }
            this.emitInput(subtractValue);
        },
        sumValue(){
            let value = Number(this.value) + 1;
            if(value > this.ceilValue){
                return;
            }
            this.emitInput(value);
        },
        rangeSlider(element: any) {
            if (this.disabled) return;
            let value = element.target.value;
            if (this.mask && this.mask == MaskType.DINHEIRO) {
                value = Money(value)?.replace(',00', '')
            }
            this.selectedOption = value;
            this.emitInput(value)
        },
        handleRangeInput(event: any) {
            let originalValue = event.target.value;
            let value = event.target.value;
            if(event.detail) {
                value =  event.detail.unmasked;
            }
            if (typeof value == 'string') {
                value = ModelForm.toNumber(value)
            }
            if (value < this.range!.min) {
                value = this.range!.min
                originalValue = Money(value)
            }
            if (value > this.range!.max) {
                value = this.range!.max
                originalValue = Money(value)
            }

            const rangeSlider: HTMLInputElement = this.$refs['range-slider'] as HTMLInputElement;

            this.selectedOption = originalValue;
            rangeSlider.value = value;
            this.emitInput(originalValue)
        
            
        },
        setRangeMinMax() {
            /*
                Aqui é onde setamos os valores iniciais do range (slider) e ficarem centralizados no thumb (bolinha).
            */
           this.rangeMinMax =  {
               minValue: {
                    inputRange: this.$refs['rangeMinMax__inputRangeMin'] as HTMLInputElement,
                    inputText: this.$refs['rangeMinMax__inputTextMin'] as HTMLInputElement,
                },

                maxValue: {
                    inputRange: this.$refs['rangeMinMax__inputRangeMax'] as HTMLInputElement,
                    inputText: this.$refs['rangeMinMax__inputTextMax'] as HTMLInputElement,
                },

                progress: this.$refs['rangeMinMax__progress'] as HTMLDivElement,
                
            } as IRangeMinMax
            
            this.gapValue = Number(this.range?.gap);
            
            if(this.rangeValues.minValue === undefined && this.rangeValues.maxValue === undefined) {
                this.rangeMinMax.minValue.inputText.value = String(this.rangeMinMax.minValue.inputRange.min);
                this.rangeMinMax.maxValue.inputText.value = String(this.rangeMinMax.minValue.inputRange.max);
    
                this.rangeMinMax.minValue.inputRange.value = this.rangeMinMax.minValue.inputText.value;
                this.rangeMinMax.maxValue.inputRange.value = this.rangeMinMax.maxValue.inputText.value;
    
            } else {
                this.rangeMinMax.minValue.inputText.value = this.rangeMinMax.minValue.inputRange.value = String(this.rangeValues.minValue)
                this.rangeMinMax.maxValue.inputText.value = this.rangeMinMax.maxValue.inputRange.value = String(this.rangeValues.maxValue)
            }
        
        },
        handleRangeMinMaxInputRange(e: any) {
            /*
                Aqui nós controlamos o range slider, definindo um valor mínimo ou máximo, e visualizando os valores nos input text;
            */
            let minVal = parseInt(this.rangeMinMax.minValue.inputRange.value),
            maxVal = parseInt(this.rangeMinMax.maxValue.inputRange.value);

            if((maxVal - minVal) < this.gapValue  && maxVal <= Number(this.rangeMinMax.maxValue.inputRange.max)){

                if(e.target.className.includes("rangeMinMax__inputRangeMin")){
                    this.rangeMinMax.minValue.inputRange.value = String(maxVal - this.gapValue);
                    
                }else{
                    this.rangeMinMax.maxValue.inputRange.value = String(minVal + this.gapValue);
                }

                return;
            }else{
                
                if(e.target.className.includes("rangeMinMax__inputRangeMin")){
                    this.rangeValues.minValue = minVal;
                    this.rangeMinMax.minValue.inputText.value = String(this.mask == 6 ? Money(minVal)?.replace(",00","") : Percent(minVal/100)?.replace(",00",""));
                    this.rangeMinMax.progress.style.left = (minVal - Number(this.rangeMinMax.minValue.inputRange.min)) / (Number(this.rangeMinMax.minValue.inputRange.max) - Number(this.rangeMinMax.minValue.inputRange.min)) * 100 + "%";
                    
                    
                } else {
                    this.rangeValues.maxValue = maxVal;
                    this.rangeMinMax.maxValue.inputText.value = String(this.mask == 6 ? Money(maxVal)?.replace(",00","") : Percent(maxVal/100)?.replace(",00",""));
                    this.rangeMinMax.progress.style.right = 100 - ( (maxVal - Number(this.rangeMinMax.minValue.inputRange.min))    /    (Number(this.rangeMinMax.maxValue.inputRange.max) - Number(this.rangeMinMax.minValue.inputRange.min))) * 100 + "%";
                }
            }

            this.emitInput(this.rangeValues)
        },
        handleRangeMinMaxInputText(e: any) {
            /*
                Aqui nós controlamos o input text, definindo um valor mínimo e máximo, e movendo o range slider de acordo com o valor.
            */
           let minPrice = ModelForm.toNumber(this.rangeMinMax.minValue.inputText.value);
           let maxPrice = ModelForm.toNumber(this.rangeMinMax.maxValue.inputText.value);

           if(minPrice > this.range!.max){
                this.rangeMinMax.minValue.inputText.value = String(this.rangeValues.minValue = this.range?.min);
           } else if(maxPrice > this.range!.max){
                this.rangeMinMax.maxValue.inputText.value = String(this.rangeValues.maxValue = this.range?.max);
           }
           if((maxPrice - minPrice >= this.gapValue) && maxPrice <= Number(this.rangeMinMax.maxValue.inputRange.max)){
                if(e.target.className.includes("rangeMinMax__inputTextMin")){
                    this.rangeMinMax.minValue.inputRange.value = String(minPrice);
                    this.rangeMinMax.progress.style.left = (minPrice - Number(this.rangeMinMax.minValue.inputRange.min)) / Number(this.rangeMinMax.minValue.inputRange.max) * 100 + "%";
                    this.rangeValues.minValue = minPrice
                }else{

                    this.rangeMinMax.maxValue.inputRange.value = String(maxPrice);
                    this.rangeMinMax.progress.style.right = 100 - (maxPrice / Number(this.rangeMinMax.maxValue.inputRange.max)) * 100 + "%";
                    this.rangeValues.maxValue = maxPrice
                }
            }
            this.emitInput(this.rangeValues)
            
        },
        handleInput(event: any) {
            let value = '' as string | number;
            if(!event.detail || !this.unMask) {
                value = event.target.value;
            } else {
                value = event.detail.unmasked;
            }
            if(this.type == InputType.PASSWORD) {
                this.$emit('verifyPassword')
            }
            if(this.type == InputType.UPDATE_NUMBER) {
                let value = Number(event.target.value);
                if(value > this.ceilValue) value = this.ceilValue;
                if(value < 1) value = 1;
                event.target.value = value
                this.emitInput(value);
                return;
            }
            if(this.type == InputType.TOKEN) {
                const input = event.target;
                if(input.value == ' ') {input.value = ''; return;};
                
                if(input.value && input.nextElementSibling) input.nextElementSibling.focus();
                
                this.emitToken()
                return;
            }
            this.emitInput(value);
        },
        emitToken() {
            const inputs = (this.$refs.tokenBox as Array<HTMLInputElement>)
            
            const token = inputs.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.value.toLocaleUpperCase();
            }, '');

            this.emitInput(token);
        },
        onSelect(option) {
            const input = option.target;
            this.emitInput(input.options[input.selectedIndex].value);
        },
        emitInput(value) {
            this.callback(value)
            this.$emit('update:value', value);
            this.$emit('input', value);
        },
        onDrop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files
            this.addFile();
        },
        dragover(event) {
            event.preventDefault()
        },
        dragleave() {

        },
        optionAlreadySelected() {
            const value = this.value;
            this.options.forEach((option: Option) => {
                if(Slugify(option.code) == Slugify(value)) {
                    this.optionSelected(option);
                    return;
                }
            });
        },
        optionSelected(option: Option) {
            if (option != undefined)
            {
                this.selected = true;
            } else {
                this.selected = false;
            }

            this.focus = false;

            this.closeSelect();
            this.emitInput(option?.code)
            if(this.type === InputType.FILTER_SELECT || this.type === InputType.AUTOCOMPLETE)
                this.$refs.selectInput.value = option?.name ? option.name : '';
            else
                this.$refs.selectInput.innerHTML = option?.name ? option.name : this.placeholder ? this.placeholder : this.label;
        },

        closeSelect() {
            this.focus = false;
        },

        focusSelect(active: boolean) {
            if(active && !this.disabled) {
                this.focus = true;
                this.$emit('scrollToBottom')
            } 
            else {
                this.closeSelect();
            }
        },
        //Filter Select

        filterSelect({target}: InputEvent) {
            this.selected = false;
            this.emitInput(undefined);
            const {value} = target as HTMLInputElement;
            this.filteredOptions = this.options.filter(({name}: Option) => name.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").search(value.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) != -1);
        },
        queryOptions({target}: InputEvent) {
            this.selected = false;
            // this.emitInput(undefined);
            const {value} = target as HTMLInputElement;
            this.$emit('getOptions', value)
        },
        disableNoUpdate() {
            this.disabled = this.isDisabled && this.value != null && this.value != '';
        },

        toggleVisibility() {
            this.showPassword = !this.showPassword
        },
        handlePasteToken() {
            const inputs = (this.$refs.tokenBox as Array<HTMLInputElement>)
            inputs.forEach((input, index) => {
                input.dataset.index = index.toString();
                input.addEventListener("paste", (e: ClipboardEvent) => {
                    const data = e.clipboardData?.getData("text");
                    const value = data?.split("");
                    if(value?.length === inputs.length) {
                        inputs.forEach((input, index) => (input.value = value[index]));
                        inputs[inputs.length - 1].focus();
                        this.emitToken()
                    }
                });
            });
        },
        handleKeyboardToken() {
            const inputs = (this.$refs.tokenBox as Array<HTMLInputElement>);
            inputs.forEach((input, index) => {
                input.addEventListener("keyup", (e: KeyboardEvent) => {
                    let inputIndex = Number(input.dataset.index);

                    if(e.key === 'Backspace' && inputIndex > 0){
                        if(inputIndex == 0) return;
                        (input.previousElementSibling as HTMLInputElement).focus();
                    }
                    
                    else if(e.key === 'ArrowLeft') {
                        if(inputIndex == 0) return;
                        (input.previousElementSibling as HTMLInputElement).focus();
                    }

                    else if(e.key === 'ArrowRight'){
                        if(inputIndex == inputs.length - 1) return;
                        (input.nextElementSibling as HTMLInputElement).focus();
                    }
                });
            });
        }
    },
    async mounted() {

        if (this.noUpdate) this.disableNoUpdate()

        if (this.type == InputType.RANGE) {
            let value = this.range?.value || this.range?.max;
            const rangeSlider: HTMLInputElement = this.$refs['range-slider'] as HTMLInputElement;
            rangeSlider.value = value;
            if (this.mask == MaskType.DINHEIRO) {
                value = Money(value);
            }
            this.emitInput(value);
        }

        if (this.value != null && this.value != undefined) {
            switch(this.type) {
                case InputType.FILTER_SELECT: {
                    this.optionAlreadySelected();
                    break;
                };
                case InputType.SELECT: {
                    this.optionAlreadySelected();
                    break;
                };
                case InputType.FILE: {
                    if((typeof this.value == 'string' || this.value.constructor.name == "File") && this.value) {
                        this.selectedOption = this.placeholder;
                    }
                    break;
                }
            }
        }
        
        if(this.type == InputType.TOKEN){
            this.handlePasteToken();
            this.handleKeyboardToken();
        }

        if(this.type == InputType.RANGE_MIN_MAX) {
            
            this.setRangeMinMax();            
        }
    },
    watch: {
        options: function(oldOptions, newOptions) {
            this.optionSelected(undefined)
            if(this.type === InputType.FILTER_SELECT || this.type === InputType.SELECT) {
                this.filteredOptions = this.options;
                if(this.value != null || this.value != '') {
                    this.optionAlreadySelected();
                }
            }
        },
        value: function(newValue, oldValue) {
            switch(this.type) {
                case InputType.FILTER_SELECT: {
                    this.optionAlreadySelected();
                    return;
                };
                case InputType.SELECT: {
                    this.optionAlreadySelected();
                    return;
                };
                case InputType.FILE: {
                    if(typeof this.value == 'string' && this.value){
                        this.selectedOption = this.placeholder;
                    }
                    if(newValue == null) {
                        this.selectedOption = ''
                    }
                };
                case InputType.TOGGLE: 
                case InputType.CHECKBOX: {
                    this.checked = newValue;
                };
            }
        },
        placeholder: function(newValue, oldValue) {
            switch(this.type) {
                case InputType.FILE: {
                    if (this.forceUpdatePlaceholder){
                        this.selectedOption = this.placeholder;
                    }
                };
            }
        },
        isDisabled: function(newValue, oldValue) {
            if (this.noUpdate) this.disableNoUpdate()
            else this.disabled = newValue
        },
        pasteClick: async function(newValue, oldValue) {
            if(oldValue != newValue) {
                const inputs = document.querySelectorAll<HTMLInputElement>(".token-box input");
                const clipboard = await navigator.clipboard.readText();
                let data = clipboard;
                const value = data?.split("");
                inputs.forEach((input, index) => {
                    
                    input.value = clipboard.length == 6 ? value[index] : '';
                });
                const pos = clipboard.length == 6 ? inputs.length - 1 : 0
                inputs[pos].focus();
                this.emitInput(clipboard);
            }
        }
    }

})
</script>

<template>
    <div class='input-field detalhe-field relative' :class="`${colSpan ? `col-span-${colSpan}` : ''} ${inputContainerClass} ${range ? '' : 'px-3'}`">
        <template v-if="!noLabel">
            <label :style="labelStyles" :for="id ? id : name" class='detalhe-label relative group py-2 my-1 label' :class="`${label ? '' : 'opacity-0' } ${noMargin ? 'w-full block !m-0 !mt-[-0.2rem] text-center' : ''} ${labelClasses}`">
                {{label ? label : name}}<span class="text-red-500">{{ isRequired && label ? " *" : ''}}</span>
                <div v-if="hint" class="absolute dark-blue rounded-full w-[1.3rem] h-[1.3rem] font-mini flex justify-center items-center top-0 " :class="hintClasses || (isRequired ? 'right-[-35%]' : 'right-[-10%]')">
                    <img :src="infoIcon"/>
                </div>
                <div v-if="hint" class="absolute top-0 left-0 hidden group-hover:block z-50">
                    <div class="relative">
                        <slot name="help" />
                    </div>
                </div>
            </label>
        </template>
        <template v-if="type === InputType.UPDATE_NUMBER">
            <div class="dark-blue-bg flex items-center justify-center px-4 py-3 rounded-[.3rem] pointer" @click="$event => subtractValue($event)">
                <i class="text-white fal fa-minus"></i>    
            </div>
            <div class='font-small break-all w-[18rem] rounded-[.5rem] py-[.8rem] hide-arrows-input'
                :class="{'!bg-white': disabled}"
                :style="styles"
                >

                <input
                    :name="name"
                    v-maska:[inputMask.options]
                    :data-maska="inputMask.default"
                    :data-maska-tokens="inputMask.tokens"
                    :data-maska-post-process="inputMask.options?.postProcess"
                    :id="id ? id : name"
                    @input="(event)=>handleInput(event)"
                    type="number"
                    min="1"
                    :required="isRequired"
                    class='input-field w-full text-[2.5rem] text-center font-medium rounded-[.5rem]'
                    :class="{'white-semi-bg': background, 'dark-mode': blueBackground, '!bg-white': disabled}"
                    :placeholder="placeholder ? placeholder : label"
                    :style="{'background-color': styles?.['background-color'], 'opacity': '1 !important', 'font-size': fontSize}"
                    :value="value"
                    :disabled="disabled"
                />
                <div>

                    
                    
                </div>
            </div>
            <div class="dark-blue-bg flex items-center justify-center px-4 py-3 rounded-[.3rem] pointer"  @click="sumValue()">
                <i class="text-white fal fa-plus"></i>    
            </div>   
        </template>

        <template v-if="type === InputType.TEXT">
            <div class='font-small w-auto break-all'
                :class="{'background-on': background, '!bg-white': disabled}"
                :style="styles"
                >
                <input
                    @focus="(event)=>$emit('focus', event)"
                    @blur="(event)=>$emit('blur', event)"
                    :name="name"
                    v-maska:[inputMask.options]
                    :data-maska="inputMask.default"
                    :data-maska-tokens="inputMask.tokens"
                    :data-maska-post-process="inputMask.options?.postProcess"
                    :id="id ? id : name"
                    @input="(event)=>handleInput(event)"
                    :type="inputTextType"
                    :required="isRequired"
                    :maxlength="maxLength"
                    class='input-field w-full'
                    :class="{'white-semi-bg': background, 'dark-mode': blueBackground, '!bg-white': disabled}"
                    :placeholder="placeholder ? placeholder : label"
                    :style="{'background-color': styles?.['background-color'], 'opacity': '1 !important', 'font-size': fontSize}"
                    :value="value"
                    :disabled="disabled"
                />
                <div>

                </div>
            </div>
        </template>
        

        <template v-if="type === InputType.TEXT_SEARCH">
            <div class='font-small w-auto break-all flex items-center'
                :class="{'background-on': background, '!bg-white': disabled}"
                :style="styles"
                >
                <input
                    
                    :name="name"
                    v-maska:[inputMask.options]
                    :data-maska="inputMask.default"
                    :data-maska-tokens="inputMask.tokens"
                    :data-maska-post-process="inputMask.options?.postProcess"
                    :id="id ? id : name"
                    @input="(event)=>handleInput(event)"
                    type="text"
                    :required="isRequired"
                    class='input-field w-full'
                    :class="{'white-semi-bg': background, 'dark-mode': blueBackground,'!bg-white': disabled}"
                    :placeholder="placeholder ? placeholder : label"
                    :style="{'background-color': styles?.['background-color'], 'opacity': '1 !important', 'font-size': fontSize}"
                    :value="value"
                    :disabled="disabled"
                />
                <img v-if="!disabled" @click="textSearchFunction(this.value)" :src="searchIcon" class="h-[1.5rem] w-[1.5rem] max:w-[1.5rem] max:h-[1.5rem] pointer text-search filter-light-grey" :class="{'rotate-180': focus}">
            </div>
        </template>
        <template v-if="type === InputType.PASSWORD">
            <div class='font-small w-auto break-all flex items-center'
                :class="{'background-on': background}"
                :style="styles"
                >
                <input
                    :name="name"
                    v-maska:[inputMask.options]
                    :type="showPassword ? 'text' : 'password'"
                    :id="id ? id : name"
                    @input="(event)=>handleInput(event)"
                    :required="isRequired"
                    class='input-field w-full'
                    :class="{'white-semi-bg': background, 'dark-mode': blueBackground}"
                    :placeholder="placeholder ? placeholder : label"
                    :style="{'background-color': styles?.['background-color'], 'opacity': '1 !important'}"
                    :value="value"
                    :disabled="disabled"
                />
                <i v-if="passwordVisibility" class="fa dark-blue cursor pointer" :class="!showPassword ? 'fa-eye': 'fa-eye-slash'" @click="toggleVisibility()"></i>
            </div>
        </template>
        <template v-if="type === InputType.TEXTAREA">
            <div class="font-small break-all"
                :class="{'background-on': background, '!bg-white': disabled}"
                :style="styles"
            >
                <textarea
                    :id="id ? id : name"
                    :required="isRequired"
                    @input="(event)=>handleInput(event)"
                    :class="[{'background-on': background, '!bg-white': disabled}, textAreaClass]"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :maxlength="maxLength"
                    v-bind:value="value"
                    ></textarea>
            </div>
        </template>
        <template v-if="type === InputType.SELECT">
            <div class="relative"
                @mouseleave="focusSelect(false)"
                >
                <div class="font-small break-all"
                :class="{'background-on': background, 'dark-mode': blueBackground, '!bg-white': disabled}"
                :style="styles"
                >
                    <div
                        ref="selectInput"
                        class='input-field w-full cursor-pointer text-ellipsis overflow-x-hidden whitespace-nowrap'
                        :class="{'white-semi-bg': background, 'dark-mode': blueBackground, 'select' :!selected, '!bg-white': disabled, 'text-center': textCenter}"
                        :style="{'background-color': styles?.['background-color']}"
                        @click="focusSelect(true)"
                        >
                        {{ placeholder ? placeholder : label }}
                    </div>
                    <input
                        type="text"
                        :required="isRequired"
                        :value="(value as any)"
                        :disabled="disabled"
                        tabindex="-1"
                        class="absolute opacity-0 z-[-100] cursor-initial left-0">
                </div>
                <!-- <input ref="filefield" type="text" class="absolute h-1 pointer-none opacity-0" :id="id || name" :name="name" :required="isRequired"> -->
                <div v-if="chevron && !disabled" @click="focusSelect(true)" class="absolute right-5 top-1 max:top-[.37rem] cursor-pointer" :class="{'white-semi-bg': !blueBackground, 'dark-mode': blueBackground}">
                    <img :src="setaBaixoIcon" class="h-[2rem] w-[2rem] max:w-[2.4rem] max:h-[2.4rem]" :class="{'rotate-180': focus}">
                </div>
                <div class="options absolute bg-white border-main w-full max-h-[20rem] ml-[-.7rem] border-main overflow-y-auto shadow-lg z-50 top-[100%]" :class="{'hidden': !focus, '!w-fit': fitContent}">
                    <div @click.stop="() => {optionSelected(option)}" v-for="option in options" class="w-full whitespace-nowrap overflow-x-hidden max-w-full white-semi-bg px-[0.7rem] h-30 font-small py-[0.1rem] border cursor-pointer hover:text-white hover-dark-blue-bg text-ellipsis">
                        {{ option.name }}
                    </div>
                </div>
            </div>
        </template>
        <template v-if="type === InputType.FILTER_SELECT">
            <div class="relative"
                @focusin="focusSelect(true)"
                @mouseleave="focusSelect(false)"
                >
                <div class="font-small break-all"
                :class="{'background-on': background, 'dark-mode': blueBackground, '!bg-white': disabled}"
                :style="styles"
                >
                    <input
                        @click="focusSelect(true)"
                        ref="selectInput"
                        @input="filterSelect"
                        autocomplete="nope"
                        type="text"
                        class='input-field w-full text-ellipsis overflow-x-hidden whitespace-nowrap'
                        :class="{'white-semi-bg': background, 'dark-mode': blueBackground, 'select': !selected, '!bg-white': disabled}"
                        :placeholder="placeholder ? placeholder : label"
                        :style="{'background-color': styles?.['background-color']}"
                        :required="isRequired"
                        :disabled="disabled"
                    >
                </div>
                <div v-if="chevron && !disabled" @click="focusSelect(true)" class="absolute cursor-pointer right-5 top-1 max:top-[.37rem]" :class="{'white-semi-bg': !blueBackground, 'dark-mode': blueBackground}">
                    <img :src="setaBaixoIcon" class="h-[2rem] w-[2rem] max:w-[2.4rem] max:h-[2.4rem]" :class="{'rotate-180': focus}">
                </div>
                <div class="options absolute bg-white border-main w-full ml-[-.7rem] max-h-[20rem] border-main overflow-y-auto shadow-lg z-50 top-[100%]" :class="focus ? maxHeight : 'hidden'">
                    <div @click="optionSelected(option)" v-for="option in (filteredOptions || options)" class="w-full text-ellipsis whitespace-nowrap overflow-x-hidden white-semi-bg px-[0.7rem] h-30 font-small py-[0.1rem] border cursor-pointer hover:text-white hover-dark-blue-bg">
                        {{ option.name }}
                    </div>
                </div>
            </div>
        </template>
        <template v-if="type === InputType.AUTOCOMPLETE">
            <div class="relative"
                @focusin="focusSelect(true)"
                @mouseleave="focusSelect(false)"
                >
                <div class="font-small break-all"
                :class="{'background-on': background, 'dark-mode': blueBackground, '!bg-white': disabled}"
                :style="styles"
                >
                    <input
                        @click="focusSelect(true)"
                        ref="selectInput"
                        @input="queryOptions"
                        autocomplete="nope"
                        type="text"
                        class='input-field w-full text-ellipsis overflow-x-hidden whitespace-nowrap'
                        :class="{'white-semi-bg': background, 'dark-mode': blueBackground, 'select': !selected, '!bg-white': disabled}"
                        :placeholder="placeholder ? placeholder : label"
                        :style="{'background-color': styles?.['background-color']}"
                        :required="isRequired"
                        :disabled="disabled"
                    >
                </div>
                <div class="options absolute bg-white border-main w-full ml-[-.7rem] max-h-[20rem] border-main overflow-y-auto shadow-lg z-50 top-[100%]" :class="focus ? maxHeight : 'hidden'">
                    <div @click="optionSelected(option)" v-for="option in autoCompleteOptions" class="w-full text-ellipsis whitespace-nowrap overflow-x-hidden white-semi-bg px-[0.7rem] h-30 font-small py-[0.1rem] border cursor-pointer hover:text-white hover-dark-blue-bg">
                        {{ option.name }}
                    </div>
                </div>
            </div>
        </template>  
        <template v-if="type === InputType.FILE">
            <div class="flex relative items-center gap-4 group py-1 negative-margin"
                @drop="onDrop"
                @dragover="dragover"
                @dragleave="dragleave"
                :class="{'!text-white': whiteMode}"
                >
                <div class="flex" >
                    <label  :for="id ? id : name" class="flex gap-main cursor-pointer font-small group relative" :class="{'py-3': noLabel}">
                        <template v-if="!userAccess?.adminJuridico">
                            <img v-if="!selectedOption" :src="addIcon" :class="{'filter-white': whiteMode}">
                        </template>
                        
                        {{!selectedOption ? placeholder : ''}}
                        <div v-if="help && !selectedOption" class="absolute border border-1 border-[#001A42] dark-blue rounded-full w-5 h-5 text-sm flex justify-center items-center top-0 right-[-1.5rem]">
                            <p :class="isRequired ? 'ml-[-1px]' : 'ml-[-.3px]'">?</p>
                        </div>
                    </label>
                    
                    <template v-if="!userAccess?.adminJuridico">
                        <input @change="addFile" :ref="'file'" type="file" :required="typeof value == 'string' && value ? false : isRequired" class="input-hidden" :accept="fileType" :name="name" :id="id ? id : name"/>
                    </template>
                </div>

                <template v-if="selectedOption">
                    <div class="flex gap-2 cursor-pointer max-w-full">
                        <p class="grey-light font-main text-ellipsis whitespace-nowrap overflow-hidden" :class="{'py-3': noLabel, '!text-white': whiteMode} ">
                            <template v-if="typeof value == 'string'">
                                <div @click="openFile">{{selectedOption}}</div>
                            </template>
                            <template v-else>
                                {{selectedOption}}
                            </template>
                        </p>
                        <template v-if="!userAccess?.adminJuridico">
                            <img v-if="!disabled" @click="removeFile" :src="deleteIcon" :class="whiteMode ? 'filter-white' : 'filter-grey'">
                        </template>
                    </div>
                </template>

                <div v-if="help" class="hidden group-hover:block absolute text-white dark-blue-bg mx-auto font-small p-3 border-main" :class="translateX ? 'translate-x-[80%]' : 'translate-y-[-100%]'">
                    {{help}}
                </div>

            </div>
        </template>
        <template v-if="type === InputType.CHECKBOX">
            <div class="w-full flex" :class="{'justify-center': noMargin, 'gap-[.7rem]': checkboxGap, 'items-center': itemsCenter}">
                <div class="min-w-[3rem] w-12 h-12 relative" :class="{'negative-margin': !noMargin}">
                    <input @click="select" :disabled="disabled" :checked="checked" type="checkbox" 
                    class="checkbox absolute opacity-0 pointer h-full w-full cursor-pointer left-0" 
                    :name="name" :required="isRequired" :id="id ? id : name"/>
                    <div class="checkmark border-main absolute w-full h-full pointer-events-none" 
                        :class="`${disabled ? '!bg-white': ''} ${checkBoxColor}`">
                    </div>
                </div>
                <label v-if="placeholder" :for="id ? id : name" class="my-1 py-1 ml-5 font-small" :class="checked ? redefinirSenhaLabelColor : 'grey-label'">
                    {{placeholder}}
                </label>
            </div>
        </template>

        <template v-if="type === InputType.RADIO">
            <div class="flex gap-8" :class="radioClasses">
                <div v-for="{label, radiusValue} in selects" @click="()=>{emitInput(radiusValue)}" class="input-wrapper pointer">
                    <div class="min-w-[3rem] relative h-full">
                        <div class="cmark" :class="{active: value == radiusValue}"></div>
                    </div>
                    <label>
                        {{ label }}
                    </label>
                </div>
            </div>
        </template>

        <template v-if="type === InputType.TOGGLE">
            <div class="flex gap-[1rem] items-center">
                <div v-if="!oneToggleLabel">
                    <p>{{ checked ? toggleSettings.on.label : toggleSettings.off.label }}</p>
                </div>
                <div v-else>
                    <p>{{ toggleSettings.off.label }}</p>
                </div>
                <div v-if="hint" class="relative hover-wrapper" :class="hintClasses"> 
                    <img :src="infoIcon" class="w-5 h-5"/>
                    <div class="hover-info toggle-hint">
                        <p v-if="checked">{{ toggleSettings.on.hintText }} <a class="hover:underline" :href="toggleSettings.url">{{ toggleLink }}</a></p>
                        <p v-else>{{ toggleSettings.off.hintText }} <a class="hover:underline" :href="toggleSettings.url">{{ toggleLink }}</a></p>
                    </div>
                </div>
                <label class="toggle" :class="{'opacity-[.7]': disabled}">
                    <input type="checkbox" :name="name" :id="id ? id : name" :disabled="disabled" @click="select" :checked="value">
                    <span class="slider"></span>
                </label>
                <p v-if="oneToggleLabel">{{ toggleSettings.on.label }}</p>
            </div>
        </template>
        
        <template v-if="type === InputType.RANGE">
            <div class="flex gap-main items-center">
                <div class="w-full relative">
                    <input @input="rangeSlider" ref="range-slider" :disabled="disabled" :id="id ? id : name" :name="name" type="range" :min="range?.min" :max="range?.max" :required="isRequired" :step="range?.step" class="range-slider w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-300" value="`${range?.value || range?.max}`">
                    <div class="absolute font-small pointer-events-none" style="bottom: -1.5rem; left: .1rem;">
                        {{ mask === MaskType.DINHEIRO ? Money(range?.min, true, true) : Percent(range?.min / 100) }} {{ range?.unit ? range.unit : '' }}
                    </div>
                    <div class="absolute font-small pointer-events-none" style="bottom: -1.5rem; right: .1rem;">
                        {{ mask === MaskType.DINHEIRO ?  Money(range?.max, true, true) : Percent(range?.max / 100) }} {{ range?.unit ? range.unit : '' }}</div>
                </div>
                <div class="h-11 w-[15rem] border-main white-semi-bg mt-[0.2rem] flex items-center justify-center flex items-center">
                    <div class="w-[80%] relative h-full flex items-center">
                        <input
                            v-maska:[inputMask.options]
                            :data-maska="inputMask.default"
                            :data-maska-tokens="inputMask.tokens"
                            :data-maska-post-process="inputMask.options?.postProcess"
                            @keyup="handleRangeInput"
                            :disabled="disabled"
                            class="input-field w-full text-center font-small bg-transparent text-blue"
                            type="text"
                            :value="selectedOption"/>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="type === InputType.RANGE_MIN_MAX">
            <div class="rangeMinMax__slider h-[.5rem] relative rounded-[.5rem] mt-[1.5rem]" style="background: #ddd;">
                <div class="rangeMinMax__progress h-full left-[25%] right-[25%] absolute rounded-[.5rem] dark-blue-bg" ref="rangeMinMax__progress"></div>
            </div>
            <div class="rangeMinMax__inputRange relative">
                <input
                    type="range"
                    class="rangeMinMax__inputRangeMin absolute w-full h-[.5rem] top-[-.5rem] pointer-events-none"
                    ref="rangeMinMax__inputRangeMin"
                    :min="range?.min"
                    :max="range?.max"
                    :step="range?.step"
                    @input="handleRangeMinMaxInputRange"
                />
                <input
                    type="range"
                    class="rangeMinMax__inputRangeMax absolute w-full h-[.5rem] top-[-.5rem] pointer-events-none"
                    ref="rangeMinMax__inputRangeMax"
                    :min="range?.min"
                    :max="range?.max"
                    :step="range?.step"
                    @input="handleRangeMinMaxInputRange"
                />
            </div>
            <div class="rangeMinMax__inputText flex place-content-between mt-[1.5rem] mb-[1rem]">
                <div class="flex flex-col gap-[.5rem]">
                    <label class="dark-blue text-[1.2rem] font-medium">Mínimo</label>
                    <input
                        @input="handleRangeMinMaxInputText"
                        type="text"
                        ref="rangeMinMax__inputTextMin"
                        class="rangeMinMax__inputTextMin text-center dark-blue text-[1.4rem] rounded-xl p-[.6rem_.8rem]"
                        :class="mask == 6 ? 'w-[12rem]' : 'w-[6rem]'"
                        v-maska:[inputMask.options]
                        :data-maska="inputMask.default"
                        :data-maska-tokens="inputMask.tokens"
                        :data-maska-post-process="inputMask.options?.postProcess"
                    />
                </div>
                <div class="flex flex-col gap-[.5rem]">
                    <label class="dark-blue text-[1.2rem] font-medium text-right">Máximo</label>
                    <input
                        @input="handleRangeMinMaxInputText"
                        type="text"
                        ref="rangeMinMax__inputTextMax"
                        class="rangeMinMax__inputTextMax text-center dark-blue text-[1.4rem] rounded-xl p-[.6rem_.8rem]"
                        :class="mask == 6 ? 'w-[12rem]' : 'w-[6rem]'"
                        v-maska:[inputMask.options]
                        :data-maska="inputMask.default"
                        :data-maska-tokens="inputMask.tokens"
                        :data-maska-post-process="inputMask.options?.postProcess"
                    />
                </div>
            </div>
        </template>

        <template v-if="type === InputType.TOKEN">
            <div class="flex justify-center token-box">
                <input v-for="input in inputsToken" 
                    :class="tokenClasses" 
                    ref="tokenBox" 
                    type="text" 
                    :required="isRequired" 
                    maxlength="1" 
                    inputmode="numeric"
                    @input="handleInput">
            </div>
        </template>
        <!-- Alterado pela dom em Form.ts -->
        <div :id="`${name}-error`" class="error-field font-mini color-error hidden">
            <p>Erro no formulário</p>
        </div>
    </div>
</template>

<style>
.select {
    color: #9ca3af;
}

.primary-label {
    font-size: 1.2rem;
    font-weight: 700;
    color: #001A42;
    margin-top: -.1rem;
}
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #001A42;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 5rem;
}

.background-on {
    border-radius: .5rem;
    background-color: rgba(234, 234, 234, 1);
    width: 100%;
    padding: .4rem .7rem;
    margin: .2rem -.7rem;
    min-height: 2.6rem;
}

.negative-margin {
    margin: 0 -.7rem;
}
.multiselect__content-wrapper{
    background-color: rgba(234, 234, 234, 1);
    position: absolute;
    min-width: fit-content;
    width: 100%;
    margin: 0 -.7rem;
    overflow-y: auto;
    padding-left: .7rem;
    z-index: 10;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.multiselect.dark-mode .multiselect__content-wrapper{
    background-color: #001A42;
}

.multiselect__input {
    outline: none !important;
    background-color: rgba(234, 234, 234, 1);
}

.multiselect.dark-mode .multiselect__input {
    outline: none !important;
    background-color: #001A42 !important;
}

.dark-mode {
    background-color: #001A42;
    color: white;
}

.multiselect__content-wrapper li{
    cursor: pointer;
    margin-top: .2rem;
    white-space: nowrap;
}

.checkmark::after {
    display: none;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: .7rem;
    height: 1.2rem;
    border: solid #001A42;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -65%) rotate(45deg);
}

.checkbox:checked~.checkmark::after {
    display: block;
}

.toggle{ 
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 1.5em;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #E7ECF2;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5em;
  width: 1.5em;
  inset: 0;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


.toggle input:checked + .slider {
  background: #001A42;
}

.toggle input:focus + .slider {
    box-shadow: 0 0 1px #001A42;
}

.toggle input:checked + .slider:before {
  transform: translateX(2em);
}

.token-box input{
    width: 50px;
    padding: 10px 5px;
    text-align: center;
    margin: 2px;
    background: #E7ECF2;
    font-weight: bold;
    color: #001A42;
    outline: none;
    text-transform: uppercase;
}

.token-box input:focus{
    box-shadow: 0 0 2px 2px #001A42;
}


.rangeMinMax__inputRange input{
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.rangeMinMax__inputText>input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.rangeMinMax__inputRange input::-webkit-slider-thumb{
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0,0,0,0.05);
}

.rangeMinMax__inputRange input::-moz-range-thumb{
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0,0,0,0.05);
}

.rangeMinMax__inputRange input::-webkit-slider-thumb:active {
    cursor: grabbing;
    border: 1px solid rgb(0,22,66);
}

.rangeMinMax__inputRange input::-moz-range-thumb:active {
    cursor: grabbing;
    border: 1px solid rgb(0,22,66);
}


</style>