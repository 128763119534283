import { PostResponse } from './Api';
import modalService from './ModalService'

export interface ResponseError {
    status: number;
    content: {message: string, url?: string, to_vue?: string, data?: string};
}

enum Errors {
    ERRO_COM_MENSAGEM = 435,
    CUSTOM_ERROR = 305,
    SEM_INCORPORADORA = 419,
    REQUEST_MUITO_GRANDE = 413,
    ERRO_BACKEND = 500,
    ERRO_AUTORIDADE = 433,
    ERRO_CSRF = 403,
    REDIRECT = 303,
    ERRO_CARTEIRA = 300,
}

export const nenhumaIncorporadoraSelecionada = () => {
    import('@/routes/incorporador').then((router) => router.default.push('/incorporadoras'))
}

export const websiteRedirect = (url: string) => {
    import('@/routes/website').then((router) => router.default.push(url))
}

export const handleErrors = (response: ResponseError): PostResponse => {
    switch(response.status) {
        case Errors.ERRO_BACKEND: {
            modalService.createError();
            break;
        }
        case Errors.SEM_INCORPORADORA: {
            nenhumaIncorporadoraSelecionada();
            break;
        }
        case Errors.ERRO_COM_MENSAGEM: {
            modalService.createError(response.content.message);
            break;
        }
        case Errors.REQUEST_MUITO_GRANDE: {
            modalService.createError("Por favor, reduza o tamanho dos arquivos antes de enviá-los (limite total de 32mb por envio)");
            break;
        }
        case Errors.ERRO_AUTORIDADE: {
            modalService.createError("É necessário ser um administrador");
            break;
        }
        case Errors.ERRO_CSRF: {
            modalService.createError("Ocorreu um erro de autenticação");
            break
        }
        case Errors.ERRO_CARTEIRA: {
            modalService.createError((response.content as any).errors);
            break;
        }
        case Errors.CUSTOM_ERROR: {
            if (response.content instanceof Blob) {
                (response.content as Blob).text().then((value) => modalService.createError(JSON.parse(value)['message']))
            } else {
                modalService.createError(String(response.content));
            }
            break;
        }
        case Errors.REDIRECT: {
            const url = response.content.url!;
            if (response.content?.to_vue) {
                websiteRedirect(url)
            } else {
                window.location.replace(url);
            }
        }
    }
    return {success: false}
}