<script>
import DownloadIcon from '@/assets/download.svg';
import Loading from './Loading.vue';
import { isMobile } from "@/services/Mobile";


export default {
    name: "DownloadButton",
    props: {
        disabled: {type:Boolean, default: false},
        height: {type: String, default: 'h-10'},
        width: {type: String, default: 'w-10'},
        backgroundColor: {type: String, default: 'dark-blue-bg' },
        classes: {type: String, default: ''},
        loading: {type: Boolean, default: false},
        text: {type: Boolean, default: false}
    },
    components: {
        Loading,
    },
    data() {
        return {
            DownloadIcon,

            mobile: isMobile(),

        }
    },
}
</script>

<template>
    <div :class="`${classes}`">
        <button v-if="!loading" :disabled="disabled" @click="(event)=>$emit('action', event)" class='detail-button disabled:bg-neutral-400 hover:underline font-bold pointer flex gap-[.5rem] justify-center items-center rounded-lg p-1'
            :class="`${height} ${width} ${backgroundColor}`">
            <p v-if="text" class="text-[1.4rem]">Baixar</p>
            <img :src="DownloadIcon" />
        </button>
        <template v-else>
            <Loading v-if="!mobile" :size="4"/>
            <Loading v-else :size="3"/>
        </template>
    </div>
</template>