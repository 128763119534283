<script lang="ts">
import { PropType, defineComponent, defineAsyncComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import { InputType, FileType } from '@/Types/InputTypes';
import addIcon from '@/assets/addIcon.svg';
import cnh_digital from '@/assets/cnh-digital.png'
import cnh_frente from '@/assets/cnh-frente.png';
import cnh_verso from '@/assets/cnh-verso.png';
import selfie from '@/assets/selfie.png';

const InputField = defineAsyncComponent(() =>
  import('@/Components/InputField.vue')
)


export default defineComponent({
    name: "ModalInputDocumentos",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        callback: {type: Function as PropType<(e:any)=>void>, default: () => {}},
        modalType:{type: String, required:true},
        placeholder: {type: String, default: "Anexar Frente"}
    },
    data(){
        return {
            addIcon,
            InputType,
            FileType,
            cnh_digital,
            cnh_frente,
            cnh_verso,
            selfie
        }
    },
    methods: {
        previewFiles(event){
            if (!this.$refs.file.files[0]) return;
        }
    },

    components: {
        ModalBase,InputField
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order" :width="modalType != 'comprovante_selfie' ? 80 : 50" :classes="`bg-white border-main white-bg flex justify-center items-center relative ${modalType != 'comprovante_selfie' ? 'h-[50rem]' : 'h-[30rem]'}`">
        <div @click="close" class="absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <h1 class="font-title justify-center flex mb-main font-bold">
                {{ modalType == "comprovante_selfie" ? "Como enviar sua selfie:" : "Como enviar fotos da CNH:" }}
            </h1>
            
            <!-- TIPOS DE CORPO DE IMAGEM -->
            <div v-if="modalType == 'documento_frente'" class="justify-center block lg:flex gap-8">
                <div>
                    <div class="flex justify-center">CNH Física (Frente):</div>
                    <img :src="cnh_frente" alt="" class="w-full lg:max-w-[40rem] max-h-[20rem]">
                </div>
                <div>
                    <div class="flex justify-center">CNH Digital (PDF):</div>
                    <img :src="cnh_digital" alt="" class="w-full lg:max-w-[40rem] max-h-[20rem]">
                </div>
            </div>

            <div v-if="modalType == 'documento_verso'" class="justify-center block lg:flex gap-8">
                <div>
                    <div class="flex justify-center">CNH Física (Verso):</div>
                    <img :src="cnh_verso" alt="" class="w-full lg:max-w-[40rem] max-h-[20rem]">
                </div>
                <div>
                    <div class="flex justify-center">CNH Digital (PDF):</div>
                    <img :src="cnh_digital" alt="" class="w-full lg:max-w-[40rem] max-h-[20rem]">
                </div>
            </div>
            
            <div v-if="modalType == 'comprovante_selfie'" class="justify-center flex gap-8">                    
                <div>
                    <img :src="selfie" alt="" class="max-w-[40rem] max-h-[20rem]">
                </div>
            </div>




            <div class="flex justify-center mt-main">
                <InputField
                @input="(e) =>{ callback(e); close()}"
                :name="modalType"
                value=""
                :fileType="FileType.ID_DOCUMENT"
                :colSpan="4"
                :type="InputType.FILE"
                :placeholder="placeholder"
                :isRequired="true"/>
            </div>
        </div>
    </ModalBase>
</template>
