import { format } from 'date-fns';
import { ModelForm } from '@/services/Form';

export const Slugify = (str: string) => {
    return String(str)
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export const Money = (value: number | String | undefined, cifrao: boolean = true, resumir: boolean = false) => {
    if(!(value instanceof String) ){
        if(value === undefined || isNaN(value)){
            return;
        }
    } 
    if(typeof(value) == 'string'){
        const regex = /\./g;
        value = value.replace(regex,"").replace(",",".")
    }
    value = Number(value)
    let returnValue: string;
    
    if (!resumir) {
        returnValue = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Number(value));
    } else {
        const negative = value < 0;
        returnValue = value.toFixed(0).toString().replace('-', '');
        let size = returnValue.length;
        let sufix: string;
        const MIL = 3,
              MM  = 6,
              BI  = 9,
              TRI = 12;

        if (size <= MIL) {
            returnValue = `${returnValue}`;
            sufix = '';
        } else if (size <= MM) {
            if (size == (MIL+1)) {
                returnValue = returnValue.slice(0, size-(MIL-1));
                returnValue = returnValue[0] + ',' + returnValue[1];
            }
            else
                returnValue = returnValue.slice(0, size-(MIL));

            sufix = 'mil';
        } else if (size <= BI) {
            if (size == (MM+1)) {
                returnValue = returnValue.slice(0, size-(MM-1));
                returnValue = returnValue[0] + ',' + returnValue[1];
            }
            else
                returnValue = returnValue.slice(0, size-MM);

            sufix = 'MM';
        } else if (size <= TRI) {
            if (size == (BI+1)) {
                returnValue = returnValue.slice(0, size-(BI-1));
                returnValue = returnValue[0] + ',' + returnValue[1];
            }
            else
                returnValue = returnValue.slice(0, size-BI);

            sufix = 'Bi';
        } else {
            if (size == (TRI+1)) {
                returnValue = returnValue.slice(0, size-(TRI-1));
                returnValue = returnValue[0] + ',' + returnValue[1];
            }
            else
                returnValue = returnValue.slice(0, size-TRI);

            sufix = 'Tri';
        }
        if(negative) {
            returnValue = '-'+returnValue;
        }
        returnValue = `${returnValue} ${sufix}`
    }

    if (cifrao) {
        returnValue = "R$ " + returnValue;
    }
    
    return returnValue;
}

export const Percent = (value: number | string | undefined, percent: boolean = true, decimalPlaces=2) => {
    if (( !value && value != 0 ) || isNaN(value as number))
        return undefined
    
    value = Number(value)

    if (percent)
        value = value*100

    let valueString = value.toFixed(decimalPlaces);
    //valueString = ModelForm.removeTrailingZeros(valueString); //Ainda não remove até duas casas decimais
    valueString = valueString.replace('.',',');
    return valueString+'%'
}

export const DateFormat = (date: string | undefined, monthOnly = false, noIso = false) => {
    if (!date)
        return '';

    if (monthOnly) {
        date = "01/"+date;
    }

    if (date.length == 8) {
        let [dia, mes, ano] = date.split("/");
        ano = "20"+ano;
        date = dia + "/" + mes + "/" + ano;
    }

    if (noIso) {
        const values = date.split("/");
        date = values.reverse().join('-');
    }

    if (!(date?.indexOf('T') > -1)) {
        date += 'T00:00:00'
    }
    return date;
}

export const DateParse = (date: string | undefined, noIso = false) => {
    if (!date)
        return 0
    return Date.parse(DateFormat(date, false, noIso));
}

export const ToDate = (date: string | undefined, full: boolean = false, noIso = false) => {
    if (!date)
        return '-';

    if (full)
        return format(DateParse(date, noIso), 'dd/MM/yyyy');

    return format(DateParse(date, noIso), 'dd/MM/yy');
}


export const ToDateWithHours = (date: string | undefined, full: boolean = false, noIso = false) => {
    if (!date) 
        return '-';

    if (full) {    
        return format(DateParse(date, noIso), 'dd/MM/yyyy HH:mm');
    }
    return format(DateParse(date, noIso), 'dd/MM/yy HH:mm');
}

export const zeroPad = (num: number | string, places: number) => String(num).padStart(places, '0')

export const formatCPF = (cpf: string) => {
    if (!cpf) {
        return '';
    }
    cpf = cpf.replace(/\D/g, ''); 
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9)}`;
}

export const formatCNPJ = (cnpj: string) => {
    if (!cnpj) {
        return '';
    }
    cnpj = cnpj.replace(/\D/g, ''); 
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`;
}

export function validateCNPJ(value: string | number | number[] = '') {
  const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/
  if (!value) return false

  const isString = typeof value === 'string'
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

  if (!validTypes) return false

  if (isString) {
    const digitsOnly = /^\d{14}$/.test(value)
    const validFormat = regexCNPJ.test(value)
    const isValid = digitsOnly || validFormat

    if (!isValid) return false
  }

  const match = value.toString().match(/\d/g)
  const numbers = Array.isArray(match) ? match.map(Number) : []

  if (numbers.length !== 14) return false

  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  const digits = numbers.slice(12)

  const digit0 = validCalc(12, numbers)
  if (digit0 !== digits[0]) return false

  const digit1 = validCalc(13, numbers)
  return digit1 === digits[1]
}

function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x)
  let factor = x - 7
  let sum = 0

  for (let i = x; i >= 1; i--) {
    const n = slice[x - i]
    sum += n * factor--
    if (factor < 2) factor = 9
  }

  const result = 11 - (sum % 11)

  return result > 9 ? 0 : result
}