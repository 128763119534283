
import { MaskType } from "../Types/MaskTypes"
import { ModelForm } from "../services/Form";

interface MaskOptions {
    options: any | null,
    default: string | string[] | null,
    tokens: string | null,
}

export const getMask = (maskType: MaskType | string) => {
    let maskOptions = {} as MaskOptions;
    
    if (Object.values(MaskType).includes(maskType)) {
        switch(maskType)
        {
            case MaskType.CPF:
            {
                maskOptions.default = "###.###.###-##";
                break;
            }
            case MaskType.CNPJ:
            {
                maskOptions.default = "##.###.###/####-##";
                break;
            }
            case MaskType.PERCENTUAL: {
                maskOptions.default = "-0,99";
                maskOptions.tokens = String.raw`-:-:optional|0:\d:multiple|9:\d:optional`;
                maskOptions.options = {
                    preProcess: (val: string) => {
                        return val.replace(/\%/g, "")
                    },
                    postProcess: (val: string) => {
                        if (val != "0" && !val) return null
                        return (Number(val.replace(',','.')) > 100 ? '100,00' : val)+'%'
                    }
                } 
                break;
              }
            case MaskType.NUMERO:
            {
                maskOptions.default = "#";
                maskOptions.tokens = String.raw`#:\d:multiple`
                break;
            }
            case MaskType.CEP:
            {
                maskOptions.default = "#####-###";
                break;
            }
            case MaskType.TELEFONE:
            {
                maskOptions.default = "(##) #####-####";
                break;
            }
            case MaskType.DECIMAL:
            {
                maskOptions.default = "0,99";
                maskOptions.options = {
                    preProcess: (val: string) => val.replace(/[$.]/g, ''),
                    postProcess: (val: any) => {
                        if (!val) return ''
                        let sub = 3 - (val.includes(',') ? val.length - val.indexOf(',') : 0)
                        sub = sub < 0 ? 0 : sub;
                        val = val.replace(/[$,]/g, '.')

                        return Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(val)
                        .slice(0, sub ? -sub : undefined)
                        .replace('R$', '')
                    }
                }
                maskOptions.tokens = String.raw`0:\d:multiple|9:\d:optional`;
                break;
            }
            case MaskType.DATA:
                {
                    maskOptions.default = "##/##/####"
                    break;
                }
            case MaskType.DATA_MIN:
                {
                    maskOptions.default = "##/##/##"
                    break;
                }
            case MaskType.DINHEIRO:
                {
                    maskOptions.default = "-0,99";
                    maskOptions.options = {
                        preProcess: (val: string) => {
                            val = val.replace(/[$.]/g, '')
                            if(val.includes('-')) {
                                val = val.replace('-', '');
                                if (val.includes('-')) {
                                    return val;
                                }
                                return "-"+val;
                            }
                            return val
                        },
                        postProcess: (val: string) => {
                            if (!val) return '';
                
                            const numberValue = Number(val.replace(",", "."));

                            let sub = 3 - (val.includes(',') ? val.length - val.indexOf(',') : 0)
                            sub = sub < 0 ? 0 : sub;
                
                            return numberValue.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).slice(0, sub ? -sub: undefined);
                        }
                    }
                    maskOptions.tokens = String.raw`-:-:optional|0:\d:multiple|9:\d:optional`;
                    break;
                }
            case MaskType.TEXTLIMIT: 
            {
                maskOptions.tokens = String.raw`'X': { pattern: /[0-9a-zA-Z]/ }`
                maskOptions.default = ''.repeat(49);
                break;
            }
        }
    } else {
        maskOptions.default = maskType as string;
    }
    return maskOptions;
}