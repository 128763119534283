<script lang="ts">
import { defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';

export default defineComponent({
    name: "Modal",
    props: {
        order: {type: Number, default: 0},
        height: {type: String, default: 'auto'},
        width: {type: String, default: 'auto'},
        pointerColor: {type: String, default: 'white'},
        classes: {type: String, default: 'dark-blue-bg flex'},
    },
    components: {
        ModalBase
    },
    emits: {
        close: null
    }
})
</script>

<template>
    <teleport to="body">
        <ModalBase @close="$emit('close')" :order="order" :width="width">
            <div :class="classes" class="border-main p-main justify-center items-center relative" :style="{width, height}">
                <div @click="$emit('close')" class="absolute top-4 right-5 cursor-pointer" :style="{color: pointerColor}">
                    <i class="fal fa-times"></i>
                </div>
                <div class="content">
                    <slot />
                </div>
            </div>
        </ModalBase>
    </teleport>
</template>