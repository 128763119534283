
<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    name: "BaseItem",
    props: {
        id: Number,
        size: Number,
        breakPointSize :{type: String, default: null},
        newSize:{type: Number, default: null},
        height: {type: Number, default: 8},
        background: {type: String, default: 'bg-white'},
        overflowVisible: {type: Boolean, default: false}
    },
})

</script>
<template>
        <div :key="id" 
            class="border-main grid tabular-nums" 
            v-bind:class="overflowVisible?'overflow-visible':'overflow-hidden'" 
            :class="`grid-cols-${newSize ? newSize : size} ${breakPointSize ? breakPointSize + ':' : ''}grid-cols-${size} ${background}`" 
            :style="`min-height: ${height}rem`">
            <slot />
        </div>
</template>