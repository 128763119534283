<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';

export default defineComponent({
    name: "ModalConfirmar",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        message: {type: String, required: true},
        width: { type: Number, default: 50 },
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order" :width="width" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div>
                <img>
            </div>
            <div class="text-center font-large text-white">
                <div  v-html="message"></div>
                <div class="flex gap-main">
                </div>
            </div>
        </div>
    </ModalBase>
</template>