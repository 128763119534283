<script lang="ts">
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'vue-chartjs'
import annotationPlugin from 'chartjs-plugin-annotation';
import { PropType, defineComponent } from 'vue';
import chartIcon from '@/assets/chart-pie.svg';
import { Money } from '@/utils/formats';



import { colors } from '@/styles/styles'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

interface BarChartData {
    labels: string[],
    data: Object[]
}

export default defineComponent({
    name: "BarChart",
    props: {
        datas: {type: Object as PropType<BarChartData>, required: false},
        label : {type: String, required: false},
        customIcon: {type: String, default: null},
        xAxisTitle: {type: String, default: 'Ano'},
        yAxisTitle: {type: String, default: null},
        icon: {type: Boolean, default: true},
        title: {type: String, default: ''},
        height: {type: Number, default: null},
        displayLegend: {type: Boolean, default: false},
        type: {type: String as PropType<'money' | 'qtd'>, default: 'money'},
        addAnnotation: {type: Object, default: null},
        formatedDate: {type: Boolean, default: false},
    },
    data() {

        const data: ChartData<'bar'> = {
            labels: this.datas.labels,
            datasets: this.datas.data 
        }

        const options: ChartOptions<'bar'> = {
            devicePixelRatio: 1.5,
            responsive: true,
            elements: {
                point: {
                    radius: 0,
                    hitRadius: 10,
                },
            },
            interaction: {
                intersect: false,
                mode: 'nearest',
            },
            scales: {
                x: {
                    stacked: true,
                    type: this.formatedDate === true ? 'time' : 'category',
                    time: {
                        parser: 'MM/YYYY',
                        displayFormats: {
                            month: 'MM/YY'
                        },
                    },
                    grid: {
                        display: true,
                        lineWidth: 1,

                    },
                    title: {
                        display: this.xAxisTitle !== "",
                        text: this.xAxisTitle,
                    }
                },
                y: {
                    stacked: true,
                    grid: {
                        display: true,
                        lineWidth: 1,
                    },
                    ticks: {
                        callback: (value, index, ticks) => {
                            return this.type == 'money' ? Money(value, true, true) : value
                        }
                    },
                    title: {
                        display: !!this.yAxisTitle,
                        text: this.yAxisTitle
                    }
                }
            },
            plugins: {
                annotation: {
                    annotations: this.addAnnotation
                },
                legend: {
                    display: this.displayLegend,
                    position: 'bottom' as const,
                    align: 'start' as const,
                    labels: {
                        filter: (item,data) => {
                            if(this.hideProjection){
                                return !((item.lineDash as any).length > 0);
                            }

                            if(this.hideLabel){
                                return !(item.text == '')
                            }
                            return Boolean(item.datasetIndex);
                        },
                        font: {
                            family: "Aeonik"
                        },
                        useBorderRadius: true,
                        borderRadius: 6,
                        padding: 20,
                        generateLabels: function(chart) {
                            const datasets = chart.data.datasets;
                            const {
                                //@ts-ignore
                                useBorderRadius,
                                //@ts-ignore
                                borderRadius,
                                //@ts-ignore
                                textAlign,
                                //@ts-ignore
                                color
                            } = chart.options?.plugins?.legend?.labels;
                            return (chart as any)._getSortedDatasetMetas().map((meta) => {
                                const style = meta.controller.getStyle(undefined);
                                return {
                                    text: datasets[meta.index].label,
                                    fillStyle: style.backgroundColor,
                                    fontColor: style.backgroundColor,
                                    hidden: !meta.visible,
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    lineWidth: 0,
                                    strokeStyle: style.borderColor,
                                    pointStyle: false,
                                    rotation: style.rotation,
                                    textAlign: textAlign || style.textAlign,
                                    borderRadius: borderRadius,
                                    padding: 20,
                                    // Below is extra data used for toggling the datasets
                                    datasetIndex: meta.index
                                };
                            })
                        }
                    },
                },
                tooltip:{
                        callbacks:{
                            label: (context) => {
                                return this.type == 'money' ? Money(context.parsed.y) : `${context.dataset.label || ''}: ${context.parsed.y}`   
                            }
                        }
                    }
            },
            
        }

        return {
            data,
            options,
            chartIcon
        }
    },
    components: {
        Bar
    }
})

</script>

<template>
    <div class="w-full flex flex-col items-center ">
        <div v-if="title" class="title w-full flex justify-center gap-main mb-main">
            <img v-if="icon" :src="!customIcon ? chartIcon : customIcon" class="filter-dark-blue"/>
            <p class="font-main font-bold">{{ title }}</p>
        </div>
        <Bar :height="height" :data="data" :options="options" />
    </div>
</template>