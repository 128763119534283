import axios, {ResponseType} from "axios";
import { ResponseError, handleErrors } from "./Erros";
import { getCookie } from "@/utils/Cookie";
import { csrfToken } from "@/services/Django";
const api = {
    get,
    post,
}

async function get<Type>(url: string, responseType: ResponseType = 'json', cors: boolean = false): Promise<Type | void> {
    const extra: any = {}

    if (!cors) {
        extra.headers = {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }

    let response: any;
    try {
        response = await axios.get<Type>(url, {responseType, ...extra});
    } catch(error: any) {
        response = error.response
    }
    if (!response) return;
    if (response.status >= 300) {
        handleErrors({status: response.status, content: response.data});
        return
    }
    if (responseType == 'blob')
        return response

    return response.data;
}

export interface PostResponse {
    success: boolean;
    message?: string;
    errors?: Object | any;
    data?: any;
}

async function post<Type>(body: Object, url: string, type: string = "multipart/form-data"): Promise<void | PostResponse> {
    let response: any;
    try {
        response = await axios.post<Type>(url, body, {headers: { "Content-Type": type, "X-CSRFToken": csrfToken || getCookie("csrftoken"), 'X-Requested-With': 'XMLHttpRequest'}});
    } catch(error : any) {
        response = error.response
    }
    if (!response) return;
    if (response.status == 210) {
        return {success: false, message: "Formulário contém erros", errors: JSON.parse(response.data.errors)};
    }
    if (response.status >= 300) {
        return handleErrors({status: response.status, content: response.data});
    }
    return {success: true, data: response.data};
}

export default api;