<script lang="ts">
    import { PropType, defineComponent } from 'vue';
    import { Loader } from '@googlemaps/js-api-loader';
    import { GOOGLE_MAPS_API_KEY } from '@/env/variables';
    import { Money } from '@/utils/formats';
    import mapPin from '@/assets/map_icon_small.png';
    import globe from '@/assets/globe.svg'
    import Loading from './Loading.vue';

    
    interface GeoData {
        nome?: string;
        latitude: number;
        longitude: number;
        peso?: number;
    }
    
    export default defineComponent({
        name: 'GoogleMap',
        props: {
            data: { type: Array as PropType<GeoData[]>, default: undefined },
            tipo: { type: String, default: 'heat'},
            peso: { type: Boolean, default: false },
            zoom: { type: Number, default: 4 },
            showTitle: {type: Boolean, default:true},
            classes: {type: String, default: ''},
            mapUrl: {type: String, default: ''},
            reloadMap: {type: Boolean, default:true},
    },
    data() {
        /* 
            Calculados com a fórmula: KmPorPx = ESCALA * (LAT(Usado a de São Paulo)*PI*180) / 2^zoom
            Variável ESCALA = 40,075(Circunferência do equador) / 256(Pixels por bloco do google)
        */
        const kmpx = [
            143.42956,
            71.714784,
            35.857392,
            17.928696,
            8.9643481,
            4.4821740,
            2.2410870,
            1.1205435,
            0.5602717,
            0.2801358,
            0.1400679,
            0.0700339,
            0.0350169,
            0.0175084,
            0.0087542,
            0.0043771,
            0.0021885,
            0.0010942,
            0.0005471
        ]

        return {
            kmpx,
            globe,
            minZoom: 14,
            loaded: false,
        };
    },
    methods: {
        getMinZoom(latDistance: number, lonDistance: number) {
            const mapHeight = this.$refs.map.clientHeight;
            const mapWidth = this.$refs.map.clientWidth;
            const grauKm = 111.1; // Para distancias pequenas: 1 grau de latitude equivale a 111.1km;
            const padding = 1.2; // Margin para os projetos não ficarem muito próximos da ponta do mapa;
            latDistance *= grauKm * padding;
            lonDistance *= grauKm * padding;
            let maxIndex = 0;
            for (let index in this.kmpx) {
                if (latDistance < this.kmpx[index]*mapHeight && lonDistance < this.kmpx[index]*mapWidth) {
                    maxIndex = Number(index);
                }
            }
            return maxIndex;
        },
        async loadMap() {
            // if(this.data == undefined) return;

            const loader = new Loader({
                apiKey: GOOGLE_MAPS_API_KEY,
                version: "weekly",
            });
            let center;
            // center = { lat: -15.666509383824332, lng: -48.05799611101819 };

            let zoom = this.zoom;

            const positions = [] as any;
            const markers = [] as any;
            const { LatLng } = await loader.importLibrary('core');
            const { Map, InfoWindow } = await loader.importLibrary('maps');
            const { Marker } = await loader.importLibrary('marker');
            const { HeatmapLayer } = await loader.importLibrary('visualization')

            if (this.tipo == 'heat') {
                if(!this.data) return;
                if (this.data.length > 0) {
                    let latTotal = 0, lonTotal = 0;
    
                    let minLat = this.data[0].latitude
                    let maxLat = this.data[0].latitude;
    
                    let minLon = this.data[0].longitude;
                    let maxLon = this.data[0].longitude;
    
                    for (let geo of (this.data as GeoData[])) {
                        latTotal += geo.latitude;
                        lonTotal += geo.longitude;
                        if(geo.latitude < minLat)
                            minLat = geo.latitude;
                        if(geo.latitude > maxLat)
                            maxLat = geo.latitude;
                        if(geo.longitude < minLon)
                            minLon = geo.longitude
                        if(geo.longitude > maxLon)
                            maxLon = geo.longitude
                    }
                    center = {lat: (maxLat+minLat)/2, lng: (maxLon+minLon)/2};
                    zoom = this.getMinZoom(maxLat-minLat, maxLon-minLon)

                } else {
                    center = { lat: -15.666509383824332, lng: -48.05799611101819 };
                }

                const mapOptions = {
                    center: center,
                    mapId: '2c9bc852e9f4b21f',
                    zoom: Math.min(this.minZoom, zoom),
                    panControl: false,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    overviewMapControl: false,
                    rotateControl: false
                };

                const gradient = [
                    "rgba(0, 150, 255, 0)",
                    "rgba(0, 80, 255, 1)",
                    "rgba(0, 63, 255, 1)",
                    "rgba(0, 20, 255, 1)",
                    "rgba(0, 0, 255, 1)",
                    "rgba(0, 0, 223, 1)",
                    "rgba(0, 0, 191, 1)",
                    "rgba(0, 0, 159, 1)",
                ];

                //Locations
                for (let index in this.data) {
                    let location = { location: new LatLng(this.data[index].latitude, this.data[index].longitude) } as {
                        location: any;
                        weight?: any;
                    };
                    if (this.peso)
                        location.weight = this.data[index].investido;
                    else
                        location.weight = 1
                    positions.push(location);
                }
                const map = new Map(document.getElementById("map") as HTMLElement, mapOptions);
                this.loaded = true;

                //Zoom event
                map.addListener("zoom_changed", () => {
                    //@ts-ignore
                    if (map.getZoom() && map.getZoom() > 8) {
                        markers.forEach(marker => marker.setMap(map));
                    }
                    else {
                        markers.forEach(marker => marker.setMap(null));
                    }
                });

                //Info Window
                const infoWindow = new InfoWindow();

                for (let index in this.data) {
                    let marker;
                    marker = new Marker({
                        position: new LatLng(this.data[index].latitude, this.data[index].longitude),
                        icon: mapPin,
                    });
                    marker.addListener('click', ((marker, index) => {
                        return () => {
                            infoWindow.setContent(`
                                <div style="background-color: #001A42; color: white">
                                    <h3 class="font-bold">${this.data[index].nome}</h3>
                                    <p>${Money(this.data[index].investido)}</p>
                                </div>`);
                            infoWindow.open(map, marker);
                        };
                    })(marker, index));
                    markers.push(marker);
                }

                //HeatMap
                const heatmap = new HeatmapLayer({
                                data: positions,
                                map,
                                radius: 30,
                            }).set("gradient", gradient);
                
            } else {
                center = {lat: this.data[0].latitude, lng: this.data[0].longitude}
                const mapOptions = {
                    zoom: 14,
                    mapId: '2c9bc852e9f4b21f',
                    center: center,
                    disableDefaultUI: true,
                    zoomControl: true,
                    scaleControl: true,
                }

                const map = new Map(document.getElementById("map") as HTMLElement, mapOptions);
                this.loaded = true;

                var marker;
                marker = new Marker({
                    position: new LatLng(this.data[0].latitude, this.data[0].longitude),
                    icon: mapPin,
                    url: this.mapUrl,
                    map: map,
                });

                    google.maps.event.addListener(marker, 'click', function() {
                        window.location.href = marker.url;
                    });
                
            }

            
            

            

            
        },
    },
    mounted() {
        this.loadMap();
    },
    watch: {
        data: function() {
            if (this.reloadMap) {
                this.loadMap();
            }
        }
    },
    components: { Loading }
})
</script>

<template>
    <div class="w-full border-main">
        <div v-if="showTitle" class="title w-full flex justify-center gap-main mb-main">
            <img :src="globe"/>
            <p class="font-main font-bold">Distribuição Geográfica</p>
        </div>
        <div class="w-fit-content relative">
            <div class="absolute center">
                <Loading v-if="!loaded"/>
            </div>
            <div id="map" ref="map" class="border-main w-full" :class="classes">
            </div>
        </div>
    </div>
</template>