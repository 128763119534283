<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';

export default defineComponent({
    name: "ModalConfirmar",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        fields: {type: Array, required: true},
        titles: {type: String, required: true}
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order">
        <div class="border-main dark-blue-bg flex justify-center items-center relative">
            <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
                <i class="fal fa-times"></i>
            </div>
            <div class="content p-main">
                <div>
                    <img>
                </div>
                <div class="text-center text-white">
                    <div class="font-large mb-main">
                        <p v-for="title in titles">
                            {{title}}
                        </p>
                    </div>
                    <div v-for="field in fields" class="color-error">
                        {{ field }}
                    </div>
                </div>
            </div>
        </div>
    </ModalBase>
</template>