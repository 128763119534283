<script lang="ts">
import plusIcon from '@/assets/plus_icon.svg';
import Loading from '@/Components/Loading.vue';
import infoIcon from '@/assets/info-icon.svg';

export default {
    name: "ActionButton",
    data() {
        return {
            plusIcon,
            infoIcon,
        }
    },
    components: {
        Loading,
    },
    props: {
        title: {type: String, required: true},
        small: {type: Boolean, default: false},
        buttonNameClass: {type: String, default: 'font-large'},
        paddingButton: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        dashed: {type: Boolean, default: false},
        icon: {type: Boolean, default: true},
        loading: {type: Boolean, default: false},
        margin: {type: String, default: 'border-main mb-main'},
        hint: {type: Boolean, default: false},
        bgButton: {type: String, default: 'dark-blue-bg'}
    }
}
</script>

<template>
    <button type="button" :disabled="disabled || loading" @click="$emit('action')" class='cadastrar-button px-4 flex justify-center text-white items-center gap-8 duration-200'
    :class="`${disabled || loading ? 'disabled' : ''} 
    ${dashed ? 'border-dashed-urbe dark-blue bg-transparent hover-anim' : bgButton} ${!small ? 'p-5' : 'py-2'} ${paddingButton} ${margin}`"
    :style="dashed && 'padding: 0 !important; min-height: 100% !important;'">
    <template v-if="!loading">

        <p :class="buttonNameClass">{{title}}</p>
        <img v-if="icon" :src="plusIcon" :class="`${!small ? 'w-8' : 'w-6'} ${dashed && 'filter-dark-blue'}`" alt=""/>
        <div v-if="hint" class="absolute smoth-start">
            <div class="top-[-2.2rem] right-[-6.2rem] group relative">
                <img :src="infoIcon" class="w-5 h-5"/>
                <slot name="hint" />
            </div>
        </div>
        </template>
        <Loading v-else/>
    </button>
</template>