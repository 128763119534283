<script lang="ts">
import { PropType, defineAsyncComponent, defineComponent, markRaw } from 'vue';
import ModalBase from './ModalBase.vue';
import SubmitButton from './SubmitButton.vue'
import facebookIcon from '@/assets/facebook.svg';
import appleIcon from '@/assets/apple.svg';
import googleIcon from '@/assets/google.svg';
import modalService from '@/services/ModalService';
import { Gateways } from '@/Types/Gateway';

import { CadastroIncorporadoraForm, CadastroInvestidorForm, DadosLoginForm, RedefinirSenhaHomeForm, Dados2FAForm, CadastroInvestidorPJForm } from '@/Types/Acesso';

import { ModelForm } from "@/services/Form";
import { authentication } from '@/services/Authentication';
import { getParams, goToUrl, redirect } from '@/services/Browser';
import { isMobileApp } from '@/services/Mobile';
import { Processing } from '@/Types/General';

const FormularioCadastroInvestidor = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/FormularioCadastroInvestidor.vue')));
const FormularioCadastroInvestidorPJ = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/FormularioCadastroInvestidorPJ.vue')));
const FormularioLogin = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/FormularioLogin.vue')));
const FormularioCadastroIncorporador = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/FormularioCadastroIncorporador.vue')));
const FormularioRedefinirSenhaHome = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/FormularioRedefinirSenhaHome.vue')));
const MudarFormulario = markRaw(defineAsyncComponent(()=>import('../Components/MudarFormulario.vue')));
const Formulario2FA = markRaw(defineAsyncComponent(()=>import('../Components/Formularios/Formulario2FA.vue')));

type INPUT_TYPE = 'login' |  'cadastro_investidor' | 'cadastro_incorporador' | 'cadastro_investidor_pj' | 'redefinir-senha' | 'mudar-formulario' | '2fa'
type FORM_TYPES = CadastroIncorporadoraForm | CadastroInvestidorForm | DadosLoginForm | RedefinirSenhaHomeForm | Dados2FAForm
type FORM_COMPONENT_TYPES = any

export default defineComponent({
    name: "ModalLoginCadastro",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        tipo: {type: String as PropType<INPUT_TYPE>, default: ''},
        gateways: {type: Object as PropType<Gateways>, required: true},
        loginPage: {type: Boolean, default: false},
    },
    components: {
        ModalBase,
        SubmitButton,
    },
    data(){
        const mobileApp = isMobileApp();
        const baseUrl = this.isMobileApp ? 'dashboard' : '/';
        const tipoFormulario = this.tipo;
        const { form, formComponent, redefinirSenhaForm} = this.getFormType(tipoFormulario)
        return {
            facebookIcon,
            appleIcon,
            googleIcon,

            processing: new Processing(),  

            showRedefinirSenha: false,

            tipoFormulario,
            form,
            formComponent,
            redefinirSenhaForm,

            isMobileApp: mobileApp,

            nextUrl: getParams('next'),
            loginInternal: getParams('internal'),

            passwordIsValid: tipoFormulario == "login",

            two_factor_username: "",
            two_factor_password: "",
        }
    },
    beforeMount() {
        if (!this.isMobileApp && authentication.isAuthenticated())
            this.redirectOnLogin();
    },
    methods: {
        getFormType(tipoFormulario: INPUT_TYPE) {
            const formType = {
                "form": null as null | FORM_TYPES,
                "formComponent": null as null | FORM_COMPONENT_TYPES,
                "redefinirSenhaForm": null as null | RedefinirSenhaHomeForm
            }
            switch(tipoFormulario) {
                case "cadastro_investidor": {
                    formType.form = new CadastroInvestidorForm(
                        //@ts-ignore
                        this.gateways.investidor.postCadastroInvestidor,
                        (any:any)=>any,
                    )
                    formType.formComponent = FormularioCadastroInvestidor;
                    break;
                }
                case "cadastro_investidor_pj": {
                    formType.form = new CadastroInvestidorPJForm(
                        //@ts-ignore
                        this.gateways.investidor.postCadastroInvestidorPJ,
                        (any:any)=>any,
                    )
                    formType.formComponent = FormularioCadastroInvestidorPJ;
                    break;
                }
                case "cadastro_incorporador": {
                    formType.form = new CadastroIncorporadoraForm(
                    //@ts-ignore
                        this.gateways.desenvolvedor.postCadastroIncorporadora,
                        (any:any)=>any,
                    )
                    formType.formComponent = FormularioCadastroIncorporador;
                    break;
                }
                case "login": {
                    formType.form = new DadosLoginForm(
                        //@ts-ignore
                        this.gateways.investidor.postLogin,
                        (any:any)=>any,
                    )
                    formType.formComponent = FormularioLogin;
                    break;
                }
                case "redefinir-senha": {
                    formType.form = new RedefinirSenhaHomeForm(
                        //@ts-ignore
                        this.gateways.investidor.postRedefinirSenhaHome,
                        (any:any)=>any,
                    )
                    formType.formComponent = FormularioRedefinirSenhaHome
                    break
                }
                case "mudar-formulario": {
                    formType.form = {instance: null} as any;
                    formType.formComponent = MudarFormulario
                    return formType;
                }
                case "2fa": {
                    formType.form = new Dados2FAForm(
                        //@ts-ignore
                        this.gateways.investidor.post2fa,
                        (any:any)=>any,
                    )
                    formType.formComponent = Formulario2FA;
                    break;
                }
            }
            formType.form.loadInstance()
            return formType;
        },
        changeForm(formType: INPUT_TYPE) {
            this.tipoFormulario = formType;
            const {form, formComponent} = this.getFormType(formType);
            this.form = form;
            this.formComponent = formComponent;
        },
        canSubmit() {
            return !this.processing.isBusyOrSetBusy();
        },

        resetSubmit(success: boolean) {
            this.processing.setValue(false);
            if (!success) return;
        },
        loginCallback(success: boolean, data: any) {
            this.processing.setValue(false);
            if (!success) {
              gtag('event', 'login_failed', {
                // 'email': this.form.instance.username,
                // 'currency': 'BRL',
                // 'value': 1000
              });
              return;
            }
            if (data.data.two_factor_authentication) {
                this.two_factor_username = data.data.username;
                this.two_factor_password = data.data.password;
                this.changeForm('2fa')
                return           
            }
            authentication.setUserAccess(data.data.permissoes_usuario);
            gtag('event', 'login', {});
            this.redirectOnLogin(data.data.redirect_url);
        },
        redirectOnLogin(redirectUrl: string = '/dashboard') {
            // if (this.loginInternal)
            //     websiteRedirect(this.nextUrl);

            // Ainda é necessário recarregar a página após o login
            redirect(this.nextUrl || redirectUrl);
            this.close();
        },
        checkPassword(valid: boolean) {
            this.passwordIsValid = valid;
        },
        goToHome(){
            if(window.location.pathname == '/login/'){
                goToUrl('/','_self')
            }
        },
        async handleSubmit(evento: SubmitEvent, form: ModelForm) {
            if(!this.canSubmit()) return;
            if(this.tipoFormulario == "redefinir-senha") {
                form.save(evento, this.resetSubmit, false, false) 
                this.close();
                modalService.mensagemComTitulo("Nós lhe enviamos um e-mail com instruções para recuperar a sua senha! Caso já exista uma conta com o e-mail informado, você receberá uma mensagem nos próximos minutos. Se não recebê-lo, por favor confirme que o e-mail informado seja o mesmo registrado e que ele não esteja na sua caixa de SPAM.", "E-mail enviado!", this.goToHome);
                return;
            }
            if (this.tipoFormulario=="login") {
                form.save(evento, this.loginCallback, false, false);
                return;
            }
            if(this.tipoFormulario=="2fa") {
                let form2fa = form as Dados2FAForm;
                form2fa.instance.username=this.two_factor_username;
                form2fa.instance.password=this.two_factor_password;
                form.save(evento, this.loginCallback, false, false);
                return
            }

            form.save(evento, this.resetSubmit, false, true, true);
            if(this.tipoFormulario == "cadastro_investidor")
              gtag('event', 'investor_subscription', {});
            else if(this.tipoFormulario == "cadastro_investidor_pj")
              gtag('event', 'investor_pj_subscription', {});
            else if(this.tipoFormulario == "cadastro_incorporador")
              gtag('event', 'incorporator_subscription', {});

        },   
        toggleRedefinirSenhaForm() {
            this.changeForm('redefinir-senha');
        },
        registerFormHandler() {
            if (this.isMobileApp)
                this.changeForm('cadastro_investidor')
            else
                this.changeForm('mudar-formulario')
        }
    },
    computed: {
        buttonText() {
            switch(this.tipoFormulario) {
                case "login": return 'Entrar';
                case "cadastro_incorporador":
                case "cadastro_investidor": return "Cadastrar";
                case "cadastro_investidor_pj": return "Cadastrar";
                case "redefinir-senha": "Redefinir Senha"
            }
        },
        bgColor(): string {
            return ["login", "mudar-formulario", "redefinir-senha", "2fa"].includes(this.tipoFormulario) ? 'dark-blue-bg' : 'white-demi-bg'
        }

    },
    mounted() {
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order + 99" :width="50" :classes="`${bgColor} pt-[5rem] border-main justify-center items-center relative md:px-[3.5rem]`">
        <div v-if="!loginPage" @click="close" class="absolute top-4 right-5 cursor-pointer" :class="['login', '2fa'].includes(tipo) ? 'dark-blue' : 'text-white'">
            <i class="fal fa-times"></i>
        </div>
        <div class="">
            <form @submit.prevent="(event)=>handleSubmit(event, form)" action="POST" enctype='multipart/form-data'>
                <component 
                    :is="formComponent"
                    :loginPage="loginPage"
                    :dadosRedefinirSenhaHome="form.instance"
                    :dadosCadastroInvestidor="form.instance"
                    :dadosCadastroIncorporadora="form.instance"
                    :dadosCadastroInvestidorPJ="form.instance"
                    :dadosLogin="form.instance"
                    :dados2FA="form.instance"
                    :generalGateway="gateways.general"
                    @check-password="checkPassword"
                    @show-redefinir-senha="toggleRedefinirSenhaForm"
                    @registerForm="registerFormHandler"
                    @change-form="changeForm"
                />

                <div v-if="tipoFormulario != 'mudar-formulario'" class="flex justify-center py-[1rem]"> 
                    <SubmitButton 
                        :text="buttonText" 
                        :noBorder="true" 
                        :noBlock="true" 
                        :classes="`
                            light-blue-bg blue-btn rounded-full tracking-[.08rem]  
                            ${tipoFormulario == 'redefinir-senha' ? ' border-solid border-[1px] border-[#001a42] mt-[1rem] h-[4.5rem]' : ' text-[1.4rem]'}
                            `" 
                        :error="false" 
                        :no-padding="true"
                        :disabled="processing.getValue() || !passwordIsValid"/>
                </div>
            </form>
        </div>
        <template v-if="!['cadastro_investidor_pj', 'cadastro_incorporador', 'redefinir-senha', 'mudar-formulario'].includes(tipoFormulario)" v-slot:footer>
            <div class="modal-footer absolute w-full top-[95%] z-[-1]">
                <div class="flex items-center justify-end">
                    <div class="w-full rounded-3xl white-bg flex justify-center items-center pb-[1rem] pt-[3.5rem] gap-[2rem]">
                        <p>Entrar com</p>
                        <a class="btn btn-social btn-facebook my-2 px-[.75rem]" href="/oauth/login/facebook"><img :src="facebookIcon"></a>
                        <a class="btn btn-social btn-google my-2 px-[.75rem]" href="/oauth/login/google-oauth2"><img :src="googleIcon"></a>
                        <a class="btn btn-social btn-apple my-2 px-[.75rem]" href="/oauth/login/apple-id"><img :src="appleIcon"></a>
                    </div>
                </div>
            </div>
        </template>
    </ModalBase>
    
</template>