<script lang="ts">
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineController,
  BubbleController,
  ChartOptions,
  ChartData,
  Filler,
} from 'chart.js';
import { Chart } from 'vue-chartjs'
import { format } from 'date-fns';

import annotationPlugin from 'chartjs-plugin-annotation';
import { colors } from '@/styles/styles'
import { DateFormat, DateParse, Money, Percent } from '@/utils/formats';
import { PropType, defineComponent } from 'vue';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  LineController,
  BubbleController,
  Filler,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);


interface DataSet {
    data: Array<[string, number]>
    label: string
    type?: string
    borderColor?: string
    backgroundColor?: string
    pointRadius?: number
    dashed?: boolean
    fillArea?: boolean
    percent?: boolean
    curve?: boolean
    conditionalColor?: boolean
    extra?: boolean
}

export default defineComponent({
    name: "LineChart",
    props: {
        dataSets: {type: Array as PropType<DataSet[]>, required: true},
        unit: {type: String, default: 'month'},
        showYLabel: {type: Boolean, default: false},
        zeroLine: {type: Boolean, default: false},
        percent: {type: Boolean, default: false},
        additionalLabelText:{type: String, default: ''},
        height: {type: Number, default: null},
        min: {type: Number as PropType<number | null>, default: 0},
        max: {type: Number as PropType<number | null>, default: 100},
        hideProjection: {type: Boolean, default: false},
        hideLabel: {type: Boolean, default: false},
        addAnnotation: {type: Object, default: null},
        showGrid: {type: Boolean, default: true },
        titleLabel: {type: Boolean, default: false},
        xAxisTitle: {type: String, default: null},
        yAxisTitle: {type: String, default: null},
    },
    data() {
        const getBorderColor = (ctx, colPositive = colors.lightBlue, colNegative = colors.lightRed, yValue = 0) => {
            if (ctx.p0.parsed.y * ctx.p1.parsed.y < yValue) {
                // if the segment changes sign from p0 to p1
                const x0 = ctx.p0.parsed.x,
                    x1 = ctx.p1.parsed.x,
                    y0 = ctx.p0.parsed.y,
                    y1 = ctx.p1.parsed.y,
                    dataset = ctx.chart.data.datasets[ctx.datasetIndex],
                    //identify the correct axes used for the dataset
                    xAxisId = dataset.xAxisId ?? "x",
                    yAxisId = dataset.yAxisId ?? "y",
                    //transform values to pixels
                    x0px = ctx.chart.scales[xAxisId].getPixelForValue(x0),
                    x1px = ctx.chart.scales[xAxisId].getPixelForValue(x1),
                    y0px = ctx.chart.scales[yAxisId].getPixelForValue(y0),
                    y1px = ctx.chart.scales[yAxisId].getPixelForValue(y1);
                
                // create gradient form p0 to p1
                const gradient = ctx.chart.ctx.createLinearGradient(x0px, y0px, x1px, y1px);
                // calculate frac - the relative length of the portion of the segment
                // from p0 to the point where the segment intersects the x axis
                const frac = Math.abs(y0) / (Math.abs(y0) + Math.abs(y1));
                // set colors at the ends of the segment
                const [col_p0, col_p1] =
                    y0 > yValue ? [colPositive, colNegative] : [colNegative, colPositive];
                gradient.addColorStop(0, col_p0);
                gradient.addColorStop(frac, col_p0);
                gradient.addColorStop(frac, col_p1);
                gradient.addColorStop(1, col_p1);
                return gradient;
            }
            return ctx.p0.parsed.y >= yValue ? colPositive : colNegative;
        }
        const options : ChartOptions<'line'> = {
            devicePixelRatio: 1.5,
            responsive: true,
            elements: {
                point: {
                    radius: 0,
                    hitRadius: 3,
                }
            },
            interaction: {
                intersect: false,
                mode: 'nearest',
            },
            plugins: {
                annotation: {
                    annotations: this.addAnnotation
                },
                legend: {
                    position: 'bottom' as const,
                    align: 'start' as const,
                    labels: {
                        filter: (item,data) => {
                            if(this.hideProjection){
                                return !((item.lineDash as any).length > 0);
                            }

                            if(this.hideLabel){
                                return !(item.text == '')
                            }
                            return true;
                        },
                        font: {
                            family: "Aeonik"
                        },
                        useBorderRadius: true,
                        borderRadius: 6,
                        padding: 20,
                        generateLabels: function(chart) {
                            const datasets = chart.data.datasets;
                            const {
                                //@ts-ignore
                                useBorderRadius,
                                //@ts-ignore
                                borderRadius,
                                //@ts-ignore
                                textAlign,
                                //@ts-ignore
                                color
                            } = chart.options?.plugins?.legend?.labels;
                            return (chart as any)._getSortedDatasetMetas().map((meta) => {
                                const style = meta.controller.getStyle(undefined);
                                return {
                                    text: datasets[meta.index].label,
                                    fillStyle: style.backgroundColor,
                                    fontColor: style.backgroundColor,
                                    hidden: !meta.visible,
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    lineWidth: 0,
                                    strokeStyle: style.borderColor,
                                    pointStyle: false,
                                    rotation: style.rotation,
                                    textAlign: textAlign || style.textAlign,
                                    borderRadius: borderRadius,
                                    padding: 20,
                                    // Below is extra data used for toggling the datasets
                                    datasetIndex: meta.index
                                };
                            })
                        }
                    },
                },
                title: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title(tooltipItems) {
                            return format(new Date(tooltipItems[0].raw.x), "MM/yy")
                        },
                        label: (tooltipItem) => {
                            let label = ''
                            if(this.titleLabel) label = tooltipItem.dataset.label+': '
                            if(this.percent) {
                                label += `${Percent(Number(tooltipItem.raw.y), false, 2)} ${this.additionalLabelText}`
                            } else {
                                label += Money(Number(tooltipItem.raw.y), true)
                            }

                            if(tooltipItem.raw?.extra) {
                                label = `${tooltipItem.raw.extra} - ${label}`
                            }

                            return label
                        },
                    }
                }
            },
            scales: {
            x: {
                bounds: 'ticks' as const,
                //@ts-ignore
                type: "time" as const, 
                time: {
                displayFormats: {
                    month: "MM/YY"
                },
                //@ts-ignore
                unit: this.unit,
                },
                title: {
                    display: !!this.xAxisTitle,
                    text: this.xAxisTitle
                },
                ticks: {
                font: {
                    family: "Aeonik",
                }
                },
                grid : {
                    display: this.showGrid,
                }
            },
            y: {
                min: this.min*-1.01 || this.min,
                max: this.max*1.01 || this.max,
                ticks: {
                    callback: (value, index, ticks) => {
                        if(this.percent) return Percent(value,false);
                        return Money(value, false, true);
                    },
                    display: this.showYLabel
                },
                title: {
                    display: !!this.yAxisTitle,
                    text: this.yAxisTitle
                },
                grid: {
                    display: this.showGrid,
                    color: (ctx, options) => {
                        if (this.zeroLine && ctx.tick.value == 0) {
                            return colors.grey;
                        }
                        return "rgba(0, 0, 0, 0.1)"
                    },
                    lineWidth(ctx, options) {
                        if (ctx.tick.value == 0) {
                            return 1;
                        }
                        return 1
                    },
                }
            }
            }
        };


        const chartData: ChartData<'line'> = {
            datasets:[]
        }

        const defaultFill = {
            target: this.zeroLine,
            below: colors.lightRedOpacity,
            above: colors.lightBlueOpacity,
        }
        
        const defaultSegment = {
            borderColor: (ctx) => {
                return getBorderColor(ctx)
            }
        }
        
        const defaultDashed = [5, 5]

        for (let dataSet of this.dataSets) {
            const dataArray = dataSet.data ? dataSet.data.map((item) => {
                const data = DateParse(DateFormat(item[0], true, true))
                const entrie: any = {x: data, y: item[1] as number}

                if (dataSet.extra)
                    entrie['extra'] = item[2]

                return entrie
            }) : []
            chartData.datasets.push({
                type: (dataSet.type as any) || 'line',
                data: dataArray,
                label: dataSet.label,
                fill: dataSet.fillArea ? defaultFill : false,
                borderColor: dataSet.borderColor || colors.darkBlue,
                backgroundColor: dataSet.backgroundColor || colors.darkBlue,
                borderDash: dataSet.dashed ? defaultDashed : undefined,
                tension: dataSet.curve ? 0.1 : 0,
                pointRadius: dataSet.pointRadius || 0,
                segment: dataSet.conditionalColor ? defaultSegment : undefined,
            })
        }

        return {
            options,
            chartData
        }
    },
    components: {
        Chart
    }
})


</script>

<template>
    <Chart
        type="line"
        :height="height"
        :data="chartData"
        :options="options"
    />
</template>