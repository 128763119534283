import { redirect } from "./Browser";
import * as Sentry from "@sentry/vue";

export const authentication = {
    isAuthenticated,
    setUserAccess,
    getUserAccess,
    logout,
    setUserAccessFromAPI
}

interface IUserAccess {
    email: string
    admin: boolean
    usuario: boolean
    incorporadora: boolean
    adminJuridico: boolean
}

function isAuthenticated() {
    let userAccess: any = localStorage.getItem("userAccess")
    if (!userAccess) return false
    userAccess = JSON.parse(userAccess as string) as IUserAccess;
    return userAccess?.usuario
}

function getUserAccess() {
    let userAccess: any = localStorage.getItem("userAccess")
    if (!userAccess) return undefined;
    return JSON.parse(userAccess as string) as any;
}

function setUserAccess(userAccess: IUserAccess | undefined) {
    Sentry.setUser({ email: userAccess?.email });
    localStorage.setItem("userAccess", JSON.stringify(userAccess))
    window.dispatchEvent(new CustomEvent('authentication-update', {
        detail: {
            storage: localStorage.getItem('userAccess')
        }
    }))
}

function logout() {
    localStorage.setItem("userAccess", '');
    redirect('/logout/')
}

function setUserAccessFromAPI(user: any) {
    const userAccess: IUserAccess = {
        email: user.email,
        admin: user.permissions.includes('admin_manager'),
        usuario: user.permissions.includes('investor_access'),
        incorporadora: user.permissions.includes('developer_access'),
        adminJuridico: user.permissions.includes('admin_juridico')
    };
    setUserAccess(userAccess);
}