<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BaseModal',
    props: {
        order: { type: Number, default: 0 },
        classes: { type: String, default: '' },
        width: { type: [Number, String], default: 50 },
    },
    data() {
        return {
            //Base index
            index: 9990 + this.order,
        }
    },
    methods: {
        close() {
            // this.$el.parentNode.removeChild(this.$el);
            this.$emit('close');
        }
    }
})
</script>

<template>
    <div id="modal" class="modal-mask fixed top-0 left-0 w-full h-full bg-[#00000080]" :style="{ 'z-index': index }">
        <div class="w-full h-full relative">
            <div @click="close" class="w-full h-full">
            </div>
            <div class="modal-wrapper justify-center items-center flex absolute center" :style="{width: `min(92.5%, ${typeof width != 'string' ? width + 'rem' : width})`}">
                <div class="modal-body w-full" data-aos="fade-down">
                    <div class="modal-content lg:min-h-[30rem] p-10 lg:h-fit h-auto" :class="classes">
                        <slot />
                    </div>
                    <slot name="footer" />
                </div>

            </div>
        </div>
    </div>
</template>