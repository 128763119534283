<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ModalBase from './ModalBase.vue';
import { Money } from '@/utils/formats';


export default defineComponent({
    name: "ModalConfirmar",
    props: {
        close: {type: Function as PropType<()=>void>, default: ()=>{}},
        order: {type: Number, default: 0},
        valor_resgate:{type: Number, required:true},
        dados_bancarios: {type: Object, required: true},
        callback: {type: Function as PropType<(arg?:any)=>void>, default: ()=>{}},
        tipo: {type: String, default: 'resgate'},
    },
    data(){
        return {Money}
    },
    components: {
        ModalBase
    }
})
</script>

<template>
    <ModalBase @close="close" :order="order" classes="bg-white border-main dark-blue-bg flex justify-center items-center relative">
        <div @click="close" class="text-white absolute top-4 right-5 cursor-pointer">
            <i class="fal fa-times"></i>
        </div>
        <div class="content p-main">
            <div class="text-center font-large text-white">
                <template v-if="tipo == 'resgate'">
                    <div class="mb-main">Valor de Resgate: {{ Money(valor_resgate) }}</div>
                    <div class="mb-main">Confira seus dados bancários:</div>
                    <div class="font-main mb-main">
                        <p>Banco: {{ dados_bancarios.banco }}</p>
                        <p>Agência: {{ dados_bancarios.agencia }}</p>
                        <p v-if="dados_bancarios.agencia_digito_verificador">Digito Verificador da Agência: {{ dados_bancarios.agencia_digito_verificador }}</p>
                        <p>Conta: {{ dados_bancarios.conta }}</p>
                        <p>Digito Verificador da Conta: {{ dados_bancarios.conta_digito_verificador }}</p>
                        <p v-if="dados_bancarios.conta_conjunta_cpf || dados_bancarios.documento_titular">
                            CPF do Titular: 
                            <span v-if="dados_bancarios.conta_conjunta">{{ dados_bancarios.conta_conjunta_cpf }}</span>
                            <span v-else>{{ dados_bancarios.documento_titular }}</span>
                        </p>
                        <p v-if="dados_bancarios.conta_conjunta">
                            Nome do Titular da Conta: {{ dados_bancarios.conta_conjunta_nome_titular }}
                        </p>
                    </div>
                </template>
                <template v-else>
                    <div class="mb-main">Valor de Doação: {{ Money(valor_resgate) }}</div>
                    <p class="font-main mb-main">
                        Você confirma esta doação para a campanha <br> <a href="https://www.instagram.com/ospa.urbe.me/p/C6mCKVKuwbr/" class="hover:underline pointer">SOS RS</a>?</p>
                </template>
                    <!-- PASSAR DADOS BANCARIO ATRAVES DE UM ARRAY -->
                    <div class="flex gap-main dark-blue">
                        <button @click="()=>{close();callback({'valor_resgate':valor_resgate})}" class="font-main p-2 w-full self-end bg-white border-main col-span-2 h-fit">
                            Confirmar
                        </button>
                        <button @click="close" class="font-main p-2 w-full self-end bg-white border-main col-span-2 h-fit">
                            Cancelar
                        </button>
                    </div>
            </div>
        </div>
    </ModalBase>
</template>