export const colors = {
    darkBlue: "#001A42",
    lightBlue: "#8CA3C8",
    lightBlueOpacity: "#8CA3C880",
    red: "#CE5252",
    lightRed: "rgb(236, 136, 136)",
    lightRedOpacity: "rgb(236, 136, 136, 0.5)",
    grey: "#A5A5A5",
    lightGrey: "#e5e7eb",
    white: "#ffff"
}