import { App, createApp } from 'vue';
import ModalError from '@/Components/ModalError.vue';
import ModalConfirmar from '@/Components/ModalConfirmar.vue';
import ModalConfirmarResgate from '@/Components/ModalConfirmarResgate.vue'
import ModalMensagem from '@/Components/ModalMensagem.vue'
import ModalEnvioMensagem from '@/Components/ModalEnvioMensagem.vue'
import ModalArquivos from '@/Components/ModalArquivos.vue'
import ModalLoginCadastro from '@/Components/ModalLoginCadastro.vue'
import ModalInvestidorPFouPJ from '@/Components/ModalInvestidorPFouPJ.vue'

import ModalPix from '@/Components/ModalPix.vue'
import ModalMensagemTitulo from '@/Components/ModalMensagemTitulo.vue';
import ModalCamposTitulo from '@/Components/ModalCamposTitulo.vue';
import ModalParcela from '@/Components/ModalParcela.vue';

import ModalInputDocumentos from '@/Components/ModalInputDocumentos.vue';
import ModalSenha from '@/Components/ModalSenha.vue';
import { Gateways } from '@/Types/Gateway';


class ModalService {
    container: HTMLElement | any;
    orderCount: number;
    constructor() {
        this.createContainer();
        this.orderCount = 1;
    }
    confirmarModal(message: string, callback: (args?: any)=>void, arg: any) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalConfirmar}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            message,
            callback,
            arg,
        });
        modal.mount(this.container);
    }
    confirmarResgateModal(valor_resgate: Number, dados_bancarios: any, callback: ()=>any) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalConfirmarResgate}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            valor_resgate,
            dados_bancarios,
            callback,
        });
        modal.mount(this.container);
    }
    pixModal(pix: any, title: string = '') {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalPix}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            pix,
            qrcodeSrc: `data:image/png;base64, ${pix.qrcode}`,
            title: title,
        });
        modal.mount(this.container);
    }
    redefinirSenhaModal(callback: Function, redefinirSenhaDashboard: Boolean = true) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalSenha}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            callback: callback,
            redefinirSenhaDashboard: redefinirSenhaDashboard
        });
        modal.mount(this.container);
    }
    inputDocumentosModal(modalType: string, placeholder: string, callback: Function) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalInputDocumentos}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            modalType: modalType,
            placeholder: placeholder,
            callback: callback
        });
        modal.mount(this.container);
    }
    modalEditParcela(instance: Object, callbackForm: Function){
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalParcela}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            instance: instance,
            callbackForm: callbackForm
        });
        modal.mount(this.container);
    };
    arquivosModal(arquivos: Array<any>, linkTodosArquivos: String) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalArquivos}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            arquivos: arquivos,
            linkTodosArquivos:linkTodosArquivos
        });
        modal.mount(this.container);
    };
    async modalLoginCadastro(tipo: string, gateways: Gateways, loginPage = false) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalLoginCadastro}, {
            order: this.orderCount++,
            close: () => {
                if (loginPage) return;
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            tipo,
            gateways,
            loginPage,
        });
        modal.mount(this.container);
    };

    mensagem(messages: string, width: Number = 50) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalMensagem}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            message: messages,
            width: width
        });
        modal.mount(this.container);
    };

    modalEnvioMensagem(title: String, subTitle: String, callback: Function) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalEnvioMensagem}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            title,
            subTitle,
            callback
        });
        modal.mount(this.container);
    };

    mensagemComTitulo(messages: string, title: string, callBack: Function = () => {}) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalMensagemTitulo}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            message: messages,
            title: title,
            callBack: callBack
        });
        modal.mount(this.container);
    };
    multiplosCamposETitulo(fields: Array<any>, titles: Array<string>) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalCamposTitulo}, {
            order: this.orderCount++,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            fields: fields,
            titles: titles
        });
        modal.mount(this.container);
    };
    createError(messages?: string | string[]) {
        this.createContainer();
        let modal: App<Element> | null = createApp({extends: ModalError}, {
            order: 99999,
            close: () => {
                this.orderCount--;
                modal?.unmount();
                modal = null;
            },
            messages: messages
        });
        modal.mount(this.container);
    };
    createContainer() {
        const oldElement = document.getElementById('modal_space');
        if(oldElement)
            oldElement.remove();

        const mountEl = document.createElement('div');
        mountEl.id = 'modal_space';
        document.body.appendChild(mountEl);
        this.container = mountEl;
    };
}

export default new ModalService();