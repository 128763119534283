<script>
export default {
    name: "CardContainer",
    props: {
        primary: Boolean,
        addClass: String
    }
}
</script>
<template>
    <div 
        class='card-container grid gap-small mb-section' 
        :class="`${primary ? 'primary' : 'secundary bg-white'}  ${addClass}`">
        <slot/>
    </div>
</template>

<style>

.card-container.primary .card {
    background-color: #001A42;
    color: white;
}
.card-container.primary .card-body {
    font-weight: 700;
}

.card-container.secundary {
    border-radius: 1rem;
}

.card-container.secundary.ativo {
    border-radius: 1rem;
    background-color: #E2ECFA;
}

.card-container.secundary.finalizado {
    border-radius: 1rem;
    background-color: #E7ECF2;
}
.card-container.secundary .card {
    color: #001A42;
}

</style>