<script lang="ts">
import { PropType, defineComponent } from 'vue';
import DownloadButton from './DownloadButton.vue';
import {DownloadButtonInterface} from '@/Types/DownloadButtonInterface';
import {isMobile} from '@/services/Mobile';


export default defineComponent({
    name: "SliderSelector",
    components: { DownloadButton},
    props: {
        sections: {type: Array as PropType<String[]>, default: null},
        downloadButton: {type:Object as PropType<DownloadButtonInterface | undefined>, default: undefined},
        justifyBetween: {type: Boolean, default: false},
        showIndex: {type: Boolean, default: true},
        freeSelect : {type:Boolean, default: true},
        lineHeightStrong: {type: Boolean, default: true}
    },
    data() {
        return {
            activeIndex: 0,
            currentWidth: 0,
            leftDistance: 0,
            isMobile
        }
    },
    methods: {
        selectIndex(index: number) {
            this.freeSelect && this.moveToSelectedIndex(index)
        },
        moveToSelectedIndex(index: number) {
            if (index == this.activeIndex)
                return;
            this.activeIndex = index;
            this.$emit("change", index);
            this.moveSlide();
        },
        moveSlide() {
            const leftBase = this.$refs['slide-wrapper'].offsetLeft;

            const currentSection = this.$refs[`section-${this.activeIndex}`][0];
            this.currentWidth = currentSection.offsetWidth;
            this.leftDistance = currentSection.offsetLeft - leftBase;
        },
    },
    mounted() {
        this.moveSlide();
    }

})
</script>

<template>
    <div class="slider-wrapper">
        <div class="sections-wrapper flex mb-8" v-bind:class = "downloadButton || justifyBetween || isMobile() ? 'justify-between' :'gap-10'">
                <div v-for="(section, key) in sections" :ref="`section-${key}`" class="section-wrapper flex items-center gap-5 cursor-pointer" v-bind:class = "downloadButton? ' mr-10':'' " @click="selectIndex(key)">
                    <div v-if="showIndex" class="index w-16 h-16 flex items-center justify-center rounded-full" :class="key == activeIndex ? 'dark-mode' : 'grey-bg'">
                        {{key + 1}}
                    </div>
                    <p class="hidden md:block">{{ section }}</p>
                </div>
            <div v-if="downloadButton" @click="downloadButton.action" class="items-center flex ml-auto gap-4 pointer">
                 <p class="font-main"><u>{{downloadButton.title}}</u></p>
                <DownloadButton :disabled="downloadButton.processing.getValue()"/>
            </div>
        </div>
        <div class="slider-selector relative border-section-bottom" ref="slide-wrapper">
            <div :class="`absolute top-0 left-0 ${lineHeightStrong ? 'h-4' : 'h-1' } dark-blue-bg base-transition`" ref="slide" :style="`width: ${currentWidth}px; transform: translate(${leftDistance}px, ${lineHeightStrong ? '-.4rem' : '0rem' });`"></div>
        </div>
    </div>
</template>