<script lang="ts">

import Acordion from './Acordion.vue'

interface AcordionContent {
    titulo: string,
    conteudo: string,
}

export default {
    name: 'CardInfoSelect',
    props: {
        title: {type: String, default: ''},
        titleClass: {type: String, default: "font-home-title"},
        subTitle: {type: String, default: ''},
        selectTitle: {type: String, default: ''},
        selectText: {type: String, default: ''},
        items: {type: Array<AcordionContent>, default: undefined},
        anchor: {type: String, default: ''},
        cardInfoFAQ: {type: Boolean, default: false},
        marginTop: {type: String, default: 'mt-[8rem]'},

    },
    components: {
        Acordion
    },
    data() {
        return {            
            height: undefined as Array<any> | undefined,
        }
    },
}
</script>

<template>
    <div :class="`gap-[2rem] px-[3.5rem] ${marginTop} py-[3rem]`" :id="anchor" data-aos="fade-right">
        <div class="how-to-title flex flex-col gap-[2rem]">
            <h2 :class="titleClass">{{ title }}</h2>
        </div>

        <div class="flex flex-col gap-[3rem]">
            <h3 class="text-[2.1rem] font-normal dark-blue mt-[3rem] leading-[1.2]" v-html="subTitle"></h3>
            <div>
                <template v-if="items" v-for="{titulo, conteudo} in items">
                    <Acordion
                        :tituloSize="cardInfoFAQ ? 'text-[2rem]' : 'text-[2.8rem]'"
                        :borderWidth="cardInfoFAQ ? 'border-t-[1px]' : 'border-[1px]'"
                        :titulo="titulo"
                        :conteudo="conteudo"
                    >
                    <template v-slot:body>
                        <p class="grey-label text-[1.9rem]" v-html="conteudo"></p>
                    </template>
                    </Acordion>       
                </template>
                <template v-else>
                    <div class="w-full skeleton h-[4rem]"> 
                    </div>
                </template>
            </div>
        </div>
        
    </div>
</template>
